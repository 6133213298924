const YesNoBothEnum = {
  Yes: 'Yes',
  No: 'No',
  Both: 'Both',
}

const YesNoBothEnumCased = {
  Yes: 'YES',
  No: 'NO',
  Both: 'BOTH',
}

type YesNoBothType = typeof YesNoBothEnum[keyof typeof YesNoBothEnum]

function getYesNoBoth(): YesNoBothType[] {
  const output = []
  output.push(YesNoBothEnum.Yes)
  output.push(YesNoBothEnum.No)
  output.push(YesNoBothEnum.Both)
  return output
}

function getYesNoMandatory(): YesNoBothType[] {
  const output = []
  output.push(YesNoBothEnum.Yes)
  output.push(YesNoBothEnum.No)
  return output
}

function printYesNo(value: YesNoBothType): string {
  switch (value) {
    case YesNoBothEnum.Yes:
      return 'Yes'
    case YesNoBothEnum.No:
      return 'No'
    case YesNoBothEnum.Both:
      return 'Both'
    case YesNoBothEnum.Both:
      return 'N/A'
    default:
      break
  }
  return 'N/A'
}

export {getYesNoBoth, printYesNo, getYesNoMandatory, YesNoBothEnum, YesNoBothEnumCased}
