
enum PromoterManagementAgentTypeEnum {
Promoter = 'Promoter',
ArtistTalentManager = 'ArtistTalentManager',
TourManager = 'TourManager',
}

type PromoterManagementAgentTypeType = typeof PromoterManagementAgentTypeEnum[keyof typeof PromoterManagementAgentTypeEnum]

function getPromoterManagementAgentTypeTypes(): PromoterManagementAgentTypeType[] {
        return Object.values(PromoterManagementAgentTypeEnum);
      } 



function printPromoterManagementAgentType(value: PromoterManagementAgentTypeEnum): string | undefined { 
switch (value) { 
       case PromoterManagementAgentTypeEnum.Promoter:
        return 'Promoter';
       case PromoterManagementAgentTypeEnum.ArtistTalentManager:
        return 'Artist / Talent Manager';
       case PromoterManagementAgentTypeEnum.TourManager:
        return 'Tour Manager';
      default:
    return "";
        }
     }


export { getPromoterManagementAgentTypeTypes, printPromoterManagementAgentType, PromoterManagementAgentTypeEnum }; 
