import {useQueryClient, useMutation} from '../../../infrastructure/deps/react-query'
import { AuthenticationRepositoryImpl } from '../../../infrastructure/remote/repositories/authentication/AuthenticationRepositoryImpl'
import {ForgotPasswordRequest, LoginRequest} from '../../models/requests/authentication'


export const useForgotPassword = (repository: AuthenticationRepositoryImpl) => {
  const queryClient = useQueryClient()

  const forgotPassword = useMutation({
    mutationFn: (request: ForgotPasswordRequest) => repository.useForgotPassword(request),
    onSuccess: (res) => {
      console.log(res)
      queryClient.invalidateQueries({queryKey: ['forgotPasswordReset']})
      // notify.success('Created Correctly!')
    },
    onError: (error) => {
      console.log(error)
      // logger.error("Something goes wrong..");
    },
  })
  return forgotPassword
}
