/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useAuth} from '../../../infrastructure/local/auth'
import {DropDownMenuOptions} from '../../menu/DropDownMenuOptions'
import RenderWhenGrid from '../RenderWhenGrid/RenderWhenGrid'
import {AssociatedUser} from '../../../domain/entities/AssociatedUser'
import {useUserSwitchAccount} from '../../../domain/usecase/user/useUserSwitchAccount'
import {UserRepositoryImpl} from '../../../infrastructure/remote/repositories/user/UserRepositoryImpl'
import {UserApi} from '../../../infrastructure/remote/api/UserApi'
import {SwitchUserRequest} from '../../../domain/models/requests/user/SwitchUserRequest'
import {HttpStatusCode} from '../../../infrastructure/enums'
import {ErrorNotification, SuccessNotification} from '../../utils/Notification'
import {Auth, User} from '../../../domain/entities'
import UserMapper from '../../../domain/mappers/UserMapper'
import {useUserLogout} from '../../../domain/usecase/user/useUserLogout'
import {UserApiRoutes} from '../../../infrastructure/constants/api/v1'
import {Http} from '../../../infrastructure/http'
import { capitalizeFirstLetter } from '../../../domain/utils'

const HeaderUserMenu: FC = () => {
  const [viewAllAccounts, setViewAllAccounts] = useState(false)
  const [isLoggingOut, setIsLoggingOut] = useState(false)
  const performAccountSwitch = useUserSwitchAccount(new UserRepositoryImpl(new UserApi()))
  const performUserLogout = useUserLogout(new UserRepositoryImpl(new UserApi()))

  const {currentUser, setCurrentUser} = useAuth()
  const {user} = currentUser
  const {associatedUuids} = user
  const navigate = useNavigate()

  const onUseUserLogout = async () => {
    const url = UserApiRoutes.Logout
    const res = await Http.get(url)
    console.log('logout response', res)
    if (res.data) {
      if (res.status === HttpStatusCode.OK) {
        setCurrentUser(undefined)
        return navigate('/', {replace: true})
      } else {
        ErrorNotification('onsubmit-log-error-failed', 'Could not log you out. Try again later')
      }
    }
  }

  const onSwitchAccount = (id: string) => {
    const request: SwitchUserRequest = {
      userId: user.uuid,
      setCurrent: id,
    }
    performAccountSwitch.mutate(request, {
      onSuccess: (response) => {
        const {status} = response
        if (status === HttpStatusCode.OK || status === HttpStatusCode.Created) {
          const mapper = new UserMapper()
          const {data} = response

          const user: User = mapper.mapToEntity(data?.user)
          setCurrentUser(data)
          // saveAuth(newAuth)

          //TODO:: show modal saying addition of account complate
          SuccessNotification('onsubmit-swa-success', 'Switched user account!')
        } else {
          ///
          ErrorNotification(
            'onsubmit-swa-error-failed',
            'Could not switch account. Try again later'
          )
        }
      },
      onError: () => {
        ErrorNotification('onsubmit-swa-error-failed', 'Could not switch account. Try again later')
      },
    })
  }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-48px me-5'>
            <div
              className='symbol-label fs-3 fw-semibold bg-secondary text-inverse-danger
            '
            >
              {capitalizeFirstLetter(associatedUuids.length>0 ? user.associatedUuids[0].name : 'US')} 
            </div>
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5 flex-wrap'>
              <span className=''>{associatedUuids.length>0 ? user.associatedUuids[0].name : 'Setup profile'}</span>
            </div>
            <a href='#' className='fw-bold text-hover-primary fs-7'>
              {currentUser?.user.name}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>
      <RenderWhenGrid visible={performAccountSwitch.isLoading}>Switching account...</RenderWhenGrid>
      <RenderWhenGrid visible={!viewAllAccounts && !performAccountSwitch.isLoading}>
        {DropDownMenuOptions.map((menuItem, index) => {
          if (menuItem.subRoutes === undefined || menuItem.subRoutes === null) {
            return (
              <>
                <div className='menu-item px-5' key={`menu-single-action-${index}`}>
                  <Link to={menuItem.route} className='menu-link px-5'>
                    {menuItem.name}
                  </Link>
                </div>
              </>
            )
          } else if (menuItem.subRoutes !== undefined && menuItem.subRoutes.length > 0) {
            return (
              <div
                className='menu-item px-5'
                data-kt-menu-trigger='hover'
                data-kt-menu-placement='left-start'
                data-kt-menu-flip='bottom'
                key={`menu-multi-action-${index}`}
              >
                <a href='#' className='menu-link px-5'>
                  <span className='menu-title'>{menuItem.name}</span>
                  <span className='menu-arrow'></span>
                </a>
                <div className='menu-sub menu-sub-dropdown w-175px py-4'>
                  {menuItem.subRoutes.map((subRoute) => {
                    return (
                      <>
                        <div className='menu-item px-3'>
                          <Link to={subRoute.route} className='menu-link d-flex flex-stack px-5'>
                            {subRoute.name}
                          </Link>
                        </div>
                      </>
                    )
                  })}
                </div>
              </div>
            )
          }
        })}
        <RenderWhenGrid visible={user?.associatedUuids?.length > 1}>
          <div className='separator my-2'></div>
          {user?.associatedUuids?.map((account: AssociatedUser, index: number) => {
            if (index <= 1) {
              return (
                <div className='menu-item px-5' key={`menu-prev-accounts-${index}}`}>
                  <span className='menu-link px-5' onClick={() => onSwitchAccount(account?.id)}>
                    {account?.name}
                  </span>
                </div>
              )
            }
          })}
        </RenderWhenGrid>
        <div className='menu-item px-5' key={`menu-prev-show-all-accounts}`}>
          <span
            className='menu-link px-5'
            onClick={(event) => {
              event.stopPropagation()
              setViewAllAccounts(true)
            }}
          >
            View All Profiles
          </span>
        </div>
        <div className='separator my-2'></div>
        <div className='menu-item px-5' key={`menu-prev-show-all-accounts}`}>
          <span className='menu-link px-5' onClick={() => onUseUserLogout()}>
            {isLoggingOut ? 'Logging out...' : 'Logout'}
          </span>
        </div>
      </RenderWhenGrid>

      <RenderWhenGrid visible={viewAllAccounts && !performAccountSwitch.isLoading}>
        {/* Go back button */}
        <div className='menu-item px-5' key={`menu-sw-account-main}`}>
          <span
            className='menu-link px-5 position-relative'
            onClick={(event) => {
              event.stopPropagation()
              setViewAllAccounts(false)
            }}
          >
            Go back
            <span className='ms-5 position-absolute translate-middle-y top-50 end-0'>&rarr;</span>
          </span>
        </div>
        <div className='separator my-2'></div>

        {/* Show all the sub accounts on this main account */}
        {user?.associatedUuids?.map((account: AssociatedUser, index: number) => {
          return (
            <div className='menu-item px-5' key={`menu-all-accounts-${index}}`}>
              <span className='menu-link px-5' onClick={() => onSwitchAccount(account?.id)}>
                {account?.name}
              </span>
            </div>
          )
        })}
        <RenderWhenGrid
          visible={user?.associatedUuids?.length < 5 || user?.associatedUuids === undefined}
        >
          <div className='menu-item px-5' key={`menu-sw-account}`}>
            <span className='menu-link px-5' onClick={() => navigate('onboarding')}>
              Add Profile
            </span>
          </div>
        </RenderWhenGrid>
      </RenderWhenGrid>
    </div>
  )
}

export {HeaderUserMenu}
