import {convertEnumToOptionsWithCleanLabel} from '../utils'

enum StudentsEnum {
  PreschoolKindergarten = 'PreschoolKindergarten',
  EarlyElementaryGrades13 = 'EarlyElementaryGrades13',
  ElementaryGrades46 = 'ElementaryGrades46',
  JuniorHighGrades79 = 'JuniorHighGrades79',
  HighSchoolGrades1012 = 'HighSchoolGrades1012',
  Precollege = 'Precollege',
  CollegeDiploma = 'CollegeDiploma',
  UniversityDegreeMastersDoctorate = 'UniversityDegreeMastersDoctorate',
  AdultGeneral = 'AdultGeneral',
  Senior65 = 'Senior65',
  KidsWithDiversabilitiesspecialNeeds = 'KidsWithDiversabilitiesspecialNeeds',
  AdultsWithDiversabilitiesspecialNeeds = 'AdultsWithDiversabilitiesspecialNeeds',
}

type StudentsType = typeof StudentsEnum[keyof typeof StudentsEnum]

function getStudentsTypes(): StudentsType[] {
  return Object.values(StudentsEnum)
}

function getStudentsEnumOptions() {
  return convertEnumToOptionsWithCleanLabel(StudentsEnum, printStudents)
}

function printStudents(value: StudentsEnum): string | undefined {
  switch (value) {
    case StudentsEnum.PreschoolKindergarten:
      return 'Pre-School / Kindergarten'
    case StudentsEnum.EarlyElementaryGrades13:
      return 'Early Elementary (grades 1-3)'
    case StudentsEnum.ElementaryGrades46:
      return 'Elementary (grades 4-6)'
    case StudentsEnum.JuniorHighGrades79:
      return 'Junior High (grades 7-9)'
    case StudentsEnum.HighSchoolGrades1012:
      return 'High School (grades 10-12)'
    case StudentsEnum.Precollege:
      return 'Pre-College'
    case StudentsEnum.CollegeDiploma:
      return 'College (Diploma)'
    case StudentsEnum.UniversityDegreeMastersDoctorate:
      return 'University (Degree, Masters, Doctorate)'
    case StudentsEnum.AdultGeneral:
      return 'Adult General'
    case StudentsEnum.Senior65:
      return 'Senior (65+)'
    case StudentsEnum.KidsWithDiversabilitiesspecialNeeds:
      return 'Kids with Diversabilities/Special Needs'
    case StudentsEnum.AdultsWithDiversabilitiesspecialNeeds:
      return 'Adults with Diversabilities/Special Needs'
    default:
      return ''
  }
}

export {getStudentsTypes, printStudents, StudentsEnum, getStudentsEnumOptions}
