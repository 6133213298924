
enum RecordingStudioExtrasEnum {
GreenRoom = 'GreenRoom',
PrivateBathroom = 'PrivateBathroom',
Accommodations = 'Accommodations',
Transportation = 'Transportation',
Kitchen = 'Kitchen',
ParkingSpaceloadingZone = 'ParkingSpaceloadingZone',
}

type RecordingStudioExtrasType = typeof RecordingStudioExtrasEnum[keyof typeof RecordingStudioExtrasEnum]

function getRecordingStudioExtrasTypes(): RecordingStudioExtrasType[] {
        return Object.values(RecordingStudioExtrasEnum);
      } 



function printRecordingStudioExtras(value: RecordingStudioExtrasEnum): string | undefined { 
switch (value) { 
       case RecordingStudioExtrasEnum.GreenRoom:
        return 'Green Room';
       case RecordingStudioExtrasEnum.PrivateBathroom:
        return 'Private Bathroom';
       case RecordingStudioExtrasEnum.Accommodations:
        return 'Accommodations';
       case RecordingStudioExtrasEnum.Transportation:
        return 'Transportation';
       case RecordingStudioExtrasEnum.Kitchen:
        return 'Kitchen';
       case RecordingStudioExtrasEnum.ParkingSpaceloadingZone:
        return 'Parking Space/Loading Zone';
      default:
    return "";
        }
     }


export { getRecordingStudioExtrasTypes, printRecordingStudioExtras, RecordingStudioExtrasEnum }; 
