import { toast } from 'react-toastify';

export function SuccessNotification(id: string, value: string) {
  toast.success(value, {
    toastId: id,
    position: 'top-right',
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: 'colored',
  });
}
export function ErrorNotification(id: string, value: string) {
  toast.error(value, {
    toastId: id,
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: 'colored',
  });
}

export function InfoNotification(id: string, value: string) {
  toast.info(value, {
    toastId: id,
    position: 'top-right',
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: 'colored',
  });
}
