import {getLength, isEmpty} from '../../../domain/utils'
import {Control, Controller} from '../../../infrastructure/deps/react-form'

interface InputTextProps {
  errors?: any
  label: string
  rows: number
  name: string
  className: string
  control: Control<any, any>
  required?: boolean
  disabled?: boolean
  showCounter?: boolean
  placeholder?: string
}

const InputTextArea = ({
  errors,
  label,
  name,
  className,
  control,
  rows,
  required,
  disabled,
  showCounter = false,
  placeholder = "",
}: InputTextProps) => {
  return (
    <>
      <label htmlFor={name} className='mt-2'>
        {label}
      </label>
      <Controller
        render={({field: {ref, onChange, value}}) => (
          <>
            <textarea
              name={name}
              className={className}
              value={value}
              onChange={onChange}
              rows={rows}
              disabled={disabled}
              placeholder={placeholder}
            ></textarea>

            <span> {showCounter ? `${getLength(value)} characters` : ''}</span>
          </>
        )}
        control={control}
        rules={{required: required}}
        name={name}
      />
      {!isEmpty(errors) ? <span className='error text-danger'>{errors}</span> : ''}
    </>
  )
}

export default InputTextArea
