import Spinner from 'react-bootstrap/Spinner'
import {BootstrapUI} from '../../../domain/enums'

interface Props {
  visible: boolean
  position: 'default' | 'center'
  variant?: BootstrapUI
}

const ProgressSpinner = ({visible, position, variant}: Props) => {
  let classNameVariant = ''
  switch (variant) {
    case BootstrapUI.Primary:
      classNameVariant = 'primary'
      break
    case BootstrapUI.Light:
      classNameVariant = 'light'
      break
    case BootstrapUI.Secondary:
      classNameVariant = 'secondary'
      break
    case BootstrapUI.Success:
      classNameVariant = 'success'
      break
    case BootstrapUI.Danger:
      classNameVariant = 'danger'
      break
    default:
      classNameVariant = 'primary'
      break
  }
  if (!visible) return <></>
  return (
    <>
      <div className={position === 'center' ? 'text-center' : ''}>
        <Spinner animation='border' role='status' variant={classNameVariant}>
          <span className='visually-hidden'>Loading...</span>
        </Spinner>
      </div>
    </>
  )
}

export default ProgressSpinner
