/* eslint-disable jsx-a11y/anchor-is-valid */
import {P, Title} from '../../../components'

export default function TermsConditions() {
  return (
    <div className='text-center'>
      <Title type='xxs'>Terms & Conditions Page</Title>
      These terms and conditions (the "Terms and Conditions") govern the use of
      unionstationcanada.ca (the "Site"). This Site is owned and operated by Union Station Canada.
      This Site is a portfolio. By using this Site, you indicate that you have read and understand
      these Terms and Conditions and agree to abide by them at all times.
      <h2 className='mt-6 text-xl'>Intellectual Property</h2>
      All content published and made available on our Site is the property of Union Station Canada
      and the Site's creators. This includes, but is not limited to images, text, logos, documents,
      downloadable files and anything that contributes to the composition of our Site.
      <h2 className='mt-6 text-xl'>Acceptable Use</h2>
      As a user of our Site, you agree to use our Site legally, not to use our Site for illegal
      purposes, and not to:
      <li>Harass or mistreat other users of our Site;</li>
      <li>Violate the rights of other users of our Site;</li>
      <li>Hack into the account of another user of the Site;</li>
      <li>Act in any way that could be considered fraudulent; or</li>
      <li>Post any material that may be deemed inappropriate or offensive.</li>
      If we believe you are using our Site illegally or in a manner that violates these Terms and
      Conditions, we reserve the right to limit, suspend or terminate your access to our Site. We
      also reserve the right to take any legal steps necessary to prevent you from accessing our
      Site.
      <h2 className='mt-6 text-xl'>User Contributions</h2>
      Users may post the following information on our Site:
      <li>Details of offered Music Services.</li>
      By posting publicly on our Site, you agree not to act illegally or violate these Terms and
      Conditions.
      <h2 className='mt-6 text-xl'>Accounts</h2>
      When you create an account on our Site, you agree to the following:
      <li>
        1. You are solely responsible for your account and the security and privacy of your account,
        including passwords or sensitive information attached to that account; and
      </li>
      <li>
        2. All personal information you provide to us through your account is up to date, accurate,
        and truthful and that you will update your personal information if it changes.
      </li>
      We reserve the right to suspend or terminate your account if you are using our Site illegally
      or if you violate these Terms and Conditions.
      <h2 className='mt-6 text-xl'>Sale of Services</h2>
      These Terms and Conditions govern the sale of services available on our Site. The following
      services are available on our Site:
      <li>Music Services and Referrals.</li>
      The Referral Fee of $20 will be paid for in full when the services are ordered. These Terms
      and Conditions apply to all the services that are displayed on our Site at the time you access
      it. All information, descriptions, or images that we provide about our services are as
      accurate as possible. However, we are not legally bound by such information, descriptions, or
      images as we cannot guarantee the accuracy of all services we provide. You agree to purchase
      services from our Site at your own risk. We reserve the right to modify, reject or cancel your
      order whenever it becomes necessary. If we cancel your order and have already processed your
      payment, we will give you a refund equal to the amount you paid. You agree that it is your
      responsibility to monitor your payment instrument to verify receipt of any refund.
      <h2 className='mt-6 text-xl'>User Goods and Services</h2>
      Our Site allows users to sell goods and services. We do not assume any responsibility for the
      goods and services users sell on our Site. We cannot guarantee the quality or accuracy of any
      goods and services sold by users on our Site. However, if we are made aware that a user is
      violating these Terms and Conditions, we reserve the right to suspend or prohibit the user
      from selling goods and services on our Site.
      <h2 className='mt-6 text-xl'>Payments</h2>
      We accept the following payment methods on our Site:
      <li>Credit Card; and</li>
      <li>Debit.</li>
      When you provide us with your payment information, you authorize our use of and access to the
      payment instrument you have chosen to use. By providing us with your payment information, you
      authorize us to charge the amount due to this payment instrument. If we believe your payment
      has violated any law or these Terms and Conditions, we reserve the right to cancel or reverse
      your transaction.
      <h2 className='mt-6 text-xl'>Consumer Protection Law</h2>
      Where the Consumer Protection Act, or any other consumer protection legislation in your
      jurisdiction applies and cannot be excluded, these Terms and Conditions will not limit your
      legal rights and remedies under that legislation. These Terms and Conditions will be read
      subject to the mandatory provisions of that legislation. If there is a conflict between these
      Terms and Conditions and that legislation, the mandatory provisions of the legislation will
      apply.
      <h2 className='mt-6 text-xl'>Limitation of Liability</h2>
      Union Station Canada and our directors, officers, agents, employees, subsidiaries, and
      affiliates will not be liable for any actions, claims, losses, damages, liabilities and
      expenses including legal fees from your use of the Site.
      <h2 className='mt-6 text-xl'>Indemnity</h2>
      Except where prohibited by law, by using this Site you indemnify and hold harmless Union
      Station Canada and our directors, officers, agents, employees, subsidiaries, and affiliates
      from any actions, claims, losses, damages, liabilities and expenses including legal fees
      arising out of your use of our Site or your violation of these Terms and Conditions.
      <h2 className='mt-6 text-xl'>Applicable Law</h2>
      These Terms and Conditions are governed by the laws of the Province of Alberta.
      <h2 className='mt-6 text-xl'>Severability</h2>
      If at any time any of the provisions set forth in these Terms and Conditions are found to be
      inconsistent or invalid under applicable laws, those provisions will be deemed void and will
      be removed from these Terms and Conditions. All other provisions will not be affected by the
      removal and the rest of these Terms and Conditions will still be considered valid.
      <h2 className='mt-6 text-xl'>Changes</h2>
      These Terms and Conditions may be amended from time to time in order to maintain compliance
      with the law and to reflect any changes to the way we operate our Site and the way we expect
      users to behave on our Site. We will notify users by email of changes to these Terms and
      Conditions or post a notice on our Site.
    </div>
  )
}
