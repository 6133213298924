import Joi from 'joi'
export const ArtistSchema = Joi.object().keys({
    id: Joi.number().integer(),
    user_id: Joi.number().integer(),
    uuid: Joi.string().uuid().allow(""),
    type: Joi.string(),
    name: Joi.string(),
    legacy_uuid: Joi.string(),
    location_id: Joi.number().integer(),
    website: Joi.string(),
    contact: Joi.string(),
    phone: Joi.string(),
    phone_main: Joi.string(),
    phone_other: Joi.string(),
    other_name: Joi.string(),
    youtube: Joi.string(),
    wikipedia: Joi.string(),
    spotify: Joi.string(),
    apple_music: Joi.string(),
    google_play: Joi.string(),
    itunes: Joi.string(),
    founded: Joi.string(),
    woman: Joi.boolean(),
    disability: Joi.boolean(),
    indigenous: Joi.boolean(),
    black: Joi.boolean(),
    hispanic_latino: Joi.boolean(),
    lgbtq: Joi.boolean(),
    size: Joi.string().valid('Small', 'Medium', 'Large', 'XtraLarge', 'NotApplicable'),
    has_label: Joi.boolean(),
    label_name: Joi.string(),
    minimum_price: Joi.number(),
    has_hourly_rate: Joi.boolean(),
    hourly_rate_price: Joi.number(),
    genres: Joi.string(),
    active: Joi.boolean(),
    created_at: Joi.date().iso(),
    updated_at: Joi.date().iso(),
  })

export async function ValidateOnboardingSchema(fields: any) {
  let errors: any = {}
  try {
    await ArtistSchema.validateAsync({...fields}, {abortEarly: false, allowUnknown: true})
  } catch (err) {
    errors['errors'] = err
  }
  return errors
}
