import {FC} from 'react'
import {ProducerEngineerFormProps} from '../../../../management/artist/props'
import {
  convertEnumToOptions,
  convertEnumToOptionsWithCleanLabel,
} from '../../../../../../domain/utils'
import {YesNoEnum, YesNoOnlyEnum} from '../../../../../../domain/enums/YesNoEnum'
import ToggleButtonGroup from '../../../../../components/ToggleButtonGroup/ToggleButtonGroup'
import {
  RecordProducerStudioEngineerEnum,
  printRecordProducerEngineer,
} from '../../../../../../domain/enums/RecordProducerStudioEngineerEnum'
import {
  ArtistSkillLevelEnum,
  printArtistSkillLevel,
} from '../../../../../../domain/enums/ArtistSkillLevelEnum'
import {
  AutoCompleteMultiple,
  InputText,
  InputTextArea,
  RenderWhenGrid,
  Title,
} from '../../../../../components'
import CommunityAdditionalInfoCard from '../../../partials/CommunityAdditionalInfoCard'
import PayRateInfoCard from '../../../partials/PayRateInfoCard'
import LocationMultiSelectCard from '../../../../../components/LocationMultiSelectCard/LocationMultiSelectCard'
import {GenresEnum, printGenres} from '../../../../../../domain/enums/GenresEnum'
import {Option} from '../../../../../interfaces/Option'
import AddressCard from '../../../partials/AddressCard'

const ProducerEngineerForm: FC<ProducerEngineerFormProps> = ({
  control,
  errors,
  watch,
  provinces,
}) => {
  const yesNoOnlyOptions = convertEnumToOptions(YesNoOnlyEnum)
  const recordProducerStudioEngineerEnumTypesOptions = convertEnumToOptionsWithCleanLabel(
    RecordProducerStudioEngineerEnum,
    printRecordProducerEngineer
  )
  const sessionInstrumentalistSkillLevelOptions = convertEnumToOptionsWithCleanLabel(
    ArtistSkillLevelEnum,
    printArtistSkillLevel
  )
  const genresOptions = convertEnumToOptionsWithCleanLabel(GenresEnum, printGenres)
  const iWorkOutOfStudio = watch('recordProducerWorkOutOfStudio')
  return (
    <>
      <div className='col-md-12 mt-5'>
        <ToggleButtonGroup
          name={'recordProcuderSessionServiceTypes'}
          label={'Type'}
          type='exclusive'
          options={recordProducerStudioEngineerEnumTypesOptions}
          control={control}
        />
      </div>

      <div className='col-md-6 mt-5'>
        <InputText
          control={control}
          errors={errors.yearsOfExperienceAsRecordProducer?.message}
          required={false}
          disabled={false}
          name={'yearsOfExperienceAsRecordProducer'}
          label={'Years of Experience as a Record Producer / Studio Engineer.'}
          className='form-control'
          type={'number'}
        />
      </div>

      <div className='col-md-12 mt-5'>
        <ToggleButtonGroup
          name={'recordProducerSkillLevel'}
          label={'Skill Level'}
          type='exclusive'
          options={sessionInstrumentalistSkillLevelOptions}
          control={control}
        />
      </div>

      <div className='col-md-12'>
        <AutoCompleteMultiple
          label={'Genre(s) (List all that apply, separated by a comma)'}
          control={control}
          options={genresOptions}
          name={'recordProducerGenres'}
          errors={errors.roles?.message}
          getOptionLabel={function (option: Option): string {
            return option.label
          }}
          getOptionValue={function (option: Option): string {
            return option.value
          }}
        />
      </div>

      <div className='col-md-12 mt-5'>
        <ToggleButtonGroup
          name={'recordProducerWorkOutOfStudio'}
          label={'I work out of a studio.'}
          type='exclusive'
          control={control}
          options={yesNoOnlyOptions}
        />
      </div>

      <RenderWhenGrid visible={iWorkOutOfStudio === YesNoOnlyEnum.Yes}>
        <div className='col-md-6 mt-5'>
          <InputText
            control={control}
            errors={errors.yearsOfExperienceAsRecordProducer?.message}
            required={false}
            disabled={false}
            name={'recordProducerStudioName'}
            label={'Studio Name'}
            className='form-control'
            type={'text'}
          />
        </div>
        <div className='col-md-12 mt-5'>
          <AddressCard
            id={'producer-eng-address'}
            label={'Studio Address'}
            control={control}
            errors={errors}
            watch={watch}
            provinceList={provinces}
            provinceLabel={'Province'}
            province={true}
            addressLineOne={true}
            addressLineTwo={true}
            city={true}
            postalCode={true}
            addressPhone={false}
            addressLineOneLabel={'Address Line 1 (required)'}
            addressLineTwoLabel={'Address Line 2'}
            cityLabel={'City (required)'}
            postalCodeLabel={'Postal Code'}
            addressPhoneLabel={'Studio Phone Number'}
          />
        </div>
      </RenderWhenGrid>

      {/* Add location template FOR STUDIO ADDRESS*/}

      <CommunityAdditionalInfoCard control={control} errors={errors} />
      <div className='col-md-12 mt-5'>
        <Title type={'xxs'}>Offer Information</Title>
      </div>
      <PayRateInfoCard
        control={control}
        errors={errors}
        rateQuestion='Minimum rate per SET or SERVICE.'
      />
    </>
  )
}

export default ProducerEngineerForm
