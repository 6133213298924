const TeacherClinicianFieldTypeEnum = {
  InstrumentStudies: 'InstrumentStudies',
  OrchestralInstrumentStudies: 'OrchestralInstrumentStudies',
  WindInstrumentStudies: 'WindInstrumentStudies',
  VocalStudies: 'VocalStudies',
  BasicTheory: 'BasicTheory',
  IntermediateTheory: 'IntermediateTheory',
  AdvancedTheory: 'AdvancedTheory',
  BasicKeyboardSkills: 'BasicKeyboardSkills',
  IntermediateKeyboardSkills: 'IntermediateKeyboardSkills',
  AdvancedKeyboardSkills: 'AdvancedKeyboardSkills',
  ClassicalMusicHistory: 'ClassicalMusicHistory',
  PopularMusicHistory: 'PopularMusicHistory',
  BasicAuralSkills: 'BasicAuralSkills',
  IntermediateAuralSkills: 'IntermediateAuralSkills',
  AdvancedAuralSkills: 'AdvancedAuralSkills',
  Composition: 'Composition',
  HarmonyCounterpoint: 'HarmonyCounterpoint',
  Songwriting: 'Songwriting',
  Arranging: 'Arranging',
  FilmScoring: 'FilmScoring',
  Performance: 'Performance',
  JazzStudies: 'JazzStudies',
  Improvisation: 'Improvisation',
  MusicTechnology: 'MusicTechnology',
  SoundProductionEngineering: 'SoundProductionEngineering',
  VideoGameScoringProduction: 'VideoGameScoringProduction',
  StageManagementTech: 'StageManagementTech',
  ArtistManagementBusiness: 'ArtistManagementBusiness',
  TourManagementMusicBusiness: 'TourManagementMusicBusiness',
  MarketingPromotion: 'MarketingPromotion',
  CopyrightLaw: 'CopyrightLaw',
  MusicEducation: 'MusicEducation',
  FunGamesActivities: 'FunGamesActivities',
  SummerCamps: 'SummerCamps',
  Piano: 'Piano',
  PopJazzPiano: 'PopJazzPiano',
  Drums: 'Drums',
  History: 'History',
  Theory: 'Theory',
  Rudiments: 'Rudiments',
  ExamPrep: 'ExamPrep',
  HarmonyAndCounterpoint: 'HarmonyAndCounterpoint',
  RecordingBasics: 'RecordingBasics',
  Technology: 'Technology',
  NotationPrograms: 'NotationPrograms',
  MasterClasses: 'MasterClasses',
  VoiceVocalCoaching: 'VoiceVocalCoaching',
}

type TeacherClinicianFieldTypeEnumType =
  typeof TeacherClinicianFieldTypeEnum[keyof typeof TeacherClinicianFieldTypeEnum]

function getTeacherClinicianFieldType(value: string): TeacherClinicianFieldTypeEnumType[] {
  const output = []
  switch (value) {
    case 'SchoolLessonsRental':
      output.push(TeacherClinicianFieldTypeEnum.SummerCamps)
      output.push(TeacherClinicianFieldTypeEnum.Improvisation)
      output.push(TeacherClinicianFieldTypeEnum.Composition)
      output.push(TeacherClinicianFieldTypeEnum.VoiceVocalCoaching)
      output.push(TeacherClinicianFieldTypeEnum.Piano)
      output.push(TeacherClinicianFieldTypeEnum.PopJazzPiano)
      output.push(TeacherClinicianFieldTypeEnum.Drums)
      output.push(TeacherClinicianFieldTypeEnum.History)
      output.push(TeacherClinicianFieldTypeEnum.Theory)
      output.push(TeacherClinicianFieldTypeEnum.Rudiments)
      output.push(TeacherClinicianFieldTypeEnum.ExamPrep)
      output.push(TeacherClinicianFieldTypeEnum.HarmonyAndCounterpoint)
      output.push(TeacherClinicianFieldTypeEnum.RecordingBasics)
      output.push(TeacherClinicianFieldTypeEnum.Technology)
      output.push(TeacherClinicianFieldTypeEnum.NotationPrograms)
      output.push(TeacherClinicianFieldTypeEnum.MasterClasses)
      break
    default:
      output.push(TeacherClinicianFieldTypeEnum.InstrumentStudies)
      output.push(TeacherClinicianFieldTypeEnum.OrchestralInstrumentStudies)
      output.push(TeacherClinicianFieldTypeEnum.WindInstrumentStudies)
      output.push(TeacherClinicianFieldTypeEnum.VocalStudies)
      output.push(TeacherClinicianFieldTypeEnum.BasicTheory)
      output.push(TeacherClinicianFieldTypeEnum.IntermediateTheory)
      output.push(TeacherClinicianFieldTypeEnum.AdvancedTheory)
      output.push(TeacherClinicianFieldTypeEnum.BasicKeyboardSkills)
      output.push(TeacherClinicianFieldTypeEnum.IntermediateKeyboardSkills)
      output.push(TeacherClinicianFieldTypeEnum.AdvancedKeyboardSkills)
      output.push(TeacherClinicianFieldTypeEnum.ClassicalMusicHistory)
      output.push(TeacherClinicianFieldTypeEnum.PopularMusicHistory)
      output.push(TeacherClinicianFieldTypeEnum.BasicAuralSkills)
      output.push(TeacherClinicianFieldTypeEnum.IntermediateAuralSkills)
      output.push(TeacherClinicianFieldTypeEnum.AdvancedAuralSkills)
      output.push(TeacherClinicianFieldTypeEnum.Composition)
      output.push(TeacherClinicianFieldTypeEnum.HarmonyCounterpoint)
      output.push(TeacherClinicianFieldTypeEnum.Songwriting)
      output.push(TeacherClinicianFieldTypeEnum.Arranging)
      output.push(TeacherClinicianFieldTypeEnum.FilmScoring)
      output.push(TeacherClinicianFieldTypeEnum.Performance)
      output.push(TeacherClinicianFieldTypeEnum.JazzStudies)
      output.push(TeacherClinicianFieldTypeEnum.Improvisation)
      output.push(TeacherClinicianFieldTypeEnum.MusicTechnology)
      output.push(TeacherClinicianFieldTypeEnum.SoundProductionEngineering)
      output.push(TeacherClinicianFieldTypeEnum.VideoGameScoringProduction)
      output.push(TeacherClinicianFieldTypeEnum.StageManagementTech)
      output.push(TeacherClinicianFieldTypeEnum.ArtistManagementBusiness)
      output.push(TeacherClinicianFieldTypeEnum.TourManagementMusicBusiness)
      output.push(TeacherClinicianFieldTypeEnum.MarketingPromotion)
      output.push(TeacherClinicianFieldTypeEnum.CopyrightLaw)
      output.push(TeacherClinicianFieldTypeEnum.MusicEducation)
      output.push(TeacherClinicianFieldTypeEnum.FunGamesActivities)
  }
  return output
}

function printTeacherClinicianFieldType(value: TeacherClinicianFieldTypeEnumType): string {
  switch (value) {
    case TeacherClinicianFieldTypeEnum.InstrumentStudies:
      return 'Instrument Studies'
    case TeacherClinicianFieldTypeEnum.OrchestralInstrumentStudies:
      return 'Orchestral Instrument Studies'
    case TeacherClinicianFieldTypeEnum.WindInstrumentStudies:
      return 'Wind Instrument Studies'
    case TeacherClinicianFieldTypeEnum.VocalStudies:
      return 'Vocal Studies'
    case TeacherClinicianFieldTypeEnum.BasicTheory:
      return 'Basic Theory'
    case TeacherClinicianFieldTypeEnum.IntermediateTheory:
      return 'Intermediate Theory'
    case TeacherClinicianFieldTypeEnum.AdvancedTheory:
      return 'Advanced Theory'
    case TeacherClinicianFieldTypeEnum.BasicKeyboardSkills:
      return 'Basic Keyboard Skills'
    case TeacherClinicianFieldTypeEnum.IntermediateKeyboardSkills:
      return 'Intermediate Keyboard Skills'
    case TeacherClinicianFieldTypeEnum.ClassicalMusicHistory:
      return 'Classical Music History'
    case TeacherClinicianFieldTypeEnum.PopularMusicHistory:
      return 'Popular Music History'
    case TeacherClinicianFieldTypeEnum.BasicAuralSkills:
      return 'Basic Aural Skills'
    case TeacherClinicianFieldTypeEnum.IntermediateAuralSkills:
      return 'Intermediate Aural Skills'
    case TeacherClinicianFieldTypeEnum.AdvancedAuralSkills:
      return 'Advanced Aural Skills'
    case TeacherClinicianFieldTypeEnum.Composition:
      return 'Composition'
    case TeacherClinicianFieldTypeEnum.HarmonyCounterpoint:
      return 'Harmony & Counterpoint'
    case TeacherClinicianFieldTypeEnum.Songwriting:
      return 'Songwriting'
    case TeacherClinicianFieldTypeEnum.Arranging:
      return 'Arranging'
    case TeacherClinicianFieldTypeEnum.FilmScoring:
      return 'Film Scoring'
    case TeacherClinicianFieldTypeEnum.Performance:
      return 'Performance'
    case TeacherClinicianFieldTypeEnum.JazzStudies:
      return 'Jazz Studies'
    case TeacherClinicianFieldTypeEnum.Improvisation:
      return 'Improvisation'
    case TeacherClinicianFieldTypeEnum.MusicTechnology:
      return 'Music Technology'
    case TeacherClinicianFieldTypeEnum.SoundProductionEngineering:
      return 'Sound Production & Engineering'
    case TeacherClinicianFieldTypeEnum.VideoGameScoringProduction:
      return 'Video Game Scoring & Production'
    case TeacherClinicianFieldTypeEnum.StageManagementTech:
      return 'Stage Management & Tech'
    case TeacherClinicianFieldTypeEnum.ArtistManagementBusiness:
      return 'Artist Management & Business'
    case TeacherClinicianFieldTypeEnum.TourManagementMusicBusiness:
      return 'Tour Management & Music Business'
    case TeacherClinicianFieldTypeEnum.MarketingPromotion:
      return 'Marketing & Promotion'
    case TeacherClinicianFieldTypeEnum.CopyrightLaw:
      return 'Copyright Law'
    case TeacherClinicianFieldTypeEnum.MusicEducation:
      return 'Music Education'
    case TeacherClinicianFieldTypeEnum.FunGamesActivities:
      return 'Fun Games & Activities'
    case TeacherClinicianFieldTypeEnum.AdvancedKeyboardSkills:
      return 'Advanced Keyboard Skills'
    case TeacherClinicianFieldTypeEnum.SummerCamps:
      return 'Summer Camps'
    case TeacherClinicianFieldTypeEnum.Piano:
      return 'Piano'
    case TeacherClinicianFieldTypeEnum.PopJazzPiano:
      return 'Pop Jazz Piano'
    case TeacherClinicianFieldTypeEnum.History:
      return 'History'
    case TeacherClinicianFieldTypeEnum.Theory:
      return 'Theory'
    case TeacherClinicianFieldTypeEnum.Rudiments:
      return 'Rudiments'
    case TeacherClinicianFieldTypeEnum.ExamPrep:
      return 'ExamPrep'
    case TeacherClinicianFieldTypeEnum.HarmonyAndCounterpoint:
      return 'Harmony & Counterpoint'
    case TeacherClinicianFieldTypeEnum.RecordingBasics:
      return 'Recording Basics'
    case TeacherClinicianFieldTypeEnum.Technology:
      return 'Technology'
    case TeacherClinicianFieldTypeEnum.NotationPrograms:
      return 'Notation Programs'
    case TeacherClinicianFieldTypeEnum.MasterClasses:
      return 'Master Classes'
    case TeacherClinicianFieldTypeEnum.Drums:
      return 'Drums'
    case TeacherClinicianFieldTypeEnum.VoiceVocalCoaching:
      return 'Voice/Vocal Coaching'
    default:
      return 'N/A'
  }
}

export {getTeacherClinicianFieldType, printTeacherClinicianFieldType, TeacherClinicianFieldTypeEnum}
