import {FC} from 'react'
import InputUrl from '../../../components/InputText copy/InputUrl'
import InputText from '../../../components/InputText/InputText'
import {CheckBox, H2, H4, InputTextArea, P, RenderWhenGrid, Title} from '../../../components'
import {convertEnumToOptions, getRequiredValidation} from '../../../../domain/utils'
import {YesNoOnlyEnum} from '../../../../domain/enums/YesNoEnum'
import ToggleButtonGroup from '../../../components/ToggleButtonGroup/ToggleButtonGroup'

export interface Props {
  id: any
  label: string
  subLabel?: string
  control: any
  errors: any
  address?: boolean
  website?: boolean
  phone?: boolean
  emailAddress?: boolean
  googlePhotos?: boolean
  googleReviews?: boolean
  wikipedia?: boolean
  spotify?: boolean
  appleMusic?: boolean
  googlePlay?: boolean
  facebook?: boolean
  instagram?: boolean
  youtube?: boolean
  tiktok?: boolean
  shareProfile?: boolean
  representingArtist?: boolean
  representingLabelOrAgency?: boolean
  name?: boolean
  // lastName?: boolean
  showSocials?: boolean
}

const ContactCard: FC<Props> = ({
  id,
  control,
  errors,
  address = false,
  website = false,
  phone = false,
  emailAddress = false,
  googlePhotos = false,
  googleReviews = false,
  wikipedia = false,
  spotify = false,
  appleMusic = false,
  googlePlay = false,
  facebook = false,
  instagram = false,
  youtube = false,
  tiktok = false,
  shareProfile = false,
  representingArtist = false,
  representingLabelOrAgency = false,
  name = false,
  // lastName = false,
  showSocials = true,
  label,
  subLabel=""
}) => {
  const yesNoOnlyOptions = convertEnumToOptions(YesNoOnlyEnum)

  return (
    <>
      <div className='col-sm-12'>
        <Title type={'xxs'}>{label}</Title>
        <P>{subLabel}</P>
      </div>
      <RenderWhenGrid visible={address}>
        <div className='col-md-6'>
          <InputTextArea
            rows={3}
            control={control}
            name={'address'}
            label={'Address'}
            errors={errors.address?.message}
            className='form-control mt-4'
          />
        </div>
      </RenderWhenGrid>

      <RenderWhenGrid visible={shareProfile}>
        <div className='col-md-6'>
          <CheckBox
            control={control}
            required={false}
            name={'shareProfile'}
            label={'Share my Union Station profile'}
            errors={errors.shareProfile?.message}
            className=' mt-5'
          />
        </div>
      </RenderWhenGrid>
      <RenderWhenGrid visible={representingArtist}>
        <div className='col-md-12 mt-5'>
          <ToggleButtonGroup
            name={'representingArtist'}
            label={'Are your representing an artist?'}
            type='exclusive'
            options={yesNoOnlyOptions}
            control={control}
          />
        </div>
      </RenderWhenGrid>
      <RenderWhenGrid visible={representingLabelOrAgency}>
        <div className='col-md-12 mt-5'>
          <ToggleButtonGroup
            name={'representingLabelOrAgency'}
            label={'Are your representing a label or agency?'}
            type='exclusive'
            options={yesNoOnlyOptions}
            control={control}
          />
        </div>
      </RenderWhenGrid>
      <RenderWhenGrid visible={name}>
        <div className='col-md-6'>
          <InputText
            control={control}
            type='text'
            required={false}
            disabled={false}
            name={'name'}
            label={'Your Name'}
            errors={errors.name?.message}
            className='form-control mt-5'
          />
        </div>
      </RenderWhenGrid>
      <RenderWhenGrid visible={emailAddress}>
        <div className='col-md-6'>
          <InputText
            control={control}
            type='email'
            name={'emailAddress'}
            required={true}
            label={'Email Address'}
            errors={getRequiredValidation(errors?.emailAddress)}
            className='form-control mt-5'
          />
        </div>
      </RenderWhenGrid>

      {/* <RenderWhenGrid visible={lastName}>
        <div className='col-md-6'>
          <InputText
            control={control}
            type='text'
            required={false}
            disabled={false}
            name={'lastName'}
            label={'Last Name'}
            errors={errors.lastName?.message}
            className='form-control mt-5'
          />
        </div>
      </RenderWhenGrid> */}
      <RenderWhenGrid visible={phone}>
        <div className='col-md-6'>
          <InputText
            control={control}
            type='text'
            required={false}
            disabled={false}
            name={'phone'}
            label={'Phone'}
            errors={errors.phone?.message}
            className='form-control mt-5'
          />
        </div>
      </RenderWhenGrid>
     
      <RenderWhenGrid visible={website}>
        <div className='col-md-6'>
          <InputUrl
            control={control}
            errors={errors.website?.message}
            required={false}
            disabled={false}
            name={'website'}
            label={'Website'}
            className='form-control'
          />
        </div>
      </RenderWhenGrid>

      <RenderWhenGrid visible={showSocials}>
        <div className='col-md-12'>
          <H4>Social Media Links</H4>
        </div>
        {/* Move */}
      </RenderWhenGrid>

      <RenderWhenGrid visible={googlePhotos}>
        <div className='col-md-6'>
          <InputUrl
            control={control}
            errors={errors.googlePhotos?.message}
            required={false}
            disabled={false}
            name={'googlePhotos'}
            label={'Google photos'}
            className='form-control'
          />
        </div>
      </RenderWhenGrid>
      <RenderWhenGrid visible={googleReviews}>
        <div className='col-md-6'>
          <InputUrl
            control={control}
            errors={errors.googleReviews?.message}
            required={false}
            disabled={false}
            name={'googleReviews'}
            label={'Google reviews'}
            className='form-control'
          />
        </div>
      </RenderWhenGrid>
      <RenderWhenGrid visible={wikipedia}>
        <div className='col-md-6'>
          <InputUrl
            control={control}
            errors={errors.wikipedia?.message}
            required={false}
            disabled={false}
            name={'wikipedia'}
            label={'Wikipedia'}
            className='form-control'
          />
        </div>
      </RenderWhenGrid>
      <RenderWhenGrid visible={spotify}>
        <div className='col-md-6'>
          <InputUrl
            control={control}
            errors={errors.artistSpotify?.message}
            required={false}
            disabled={false}
            name={'spotify'}
            label={'Spotify'}
            className='form-control'
          />
        </div>
      </RenderWhenGrid>
      <RenderWhenGrid visible={appleMusic}>
        <div className='col-md-6'>
          <InputUrl
            control={control}
            errors={errors.artistAppleMusic?.message}
            required={false}
            disabled={false}
            name={'appleMusic'}
            label={'Apple Music'}
            className='form-control'
          />
        </div>
      </RenderWhenGrid>
      <RenderWhenGrid visible={googlePlay}>
        <div className='col-md-6'>
          <InputUrl
            control={control}
            errors={errors.artistGooglePlay?.message}
            required={false}
            disabled={false}
            name={'googlePlay'}
            label={'Google Play'}
            className='form-control'
          />
        </div>
      </RenderWhenGrid>
      <RenderWhenGrid visible={facebook}>
        <div className='col-md-6'>
          <InputUrl
            control={control}
            errors={errors.facebookUrl?.message}
            required={false}
            disabled={false}
            name={'facebookUrl'}
            label={'Facebook'}
            className='form-control'
          />
        </div>
      </RenderWhenGrid>
      <RenderWhenGrid visible={instagram}>
        <div className='col-md-6'>
          <InputUrl
            control={control}
            errors={errors.instagramUrl?.message}
            required={false}
            disabled={false}
            name={'instagramUrl'}
            label={'Instagram'}
            className='form-control'
          />
        </div>
      </RenderWhenGrid>
      <RenderWhenGrid visible={youtube}>
        <div className='col-md-6'>
          <InputUrl
            control={control}
            errors={errors.youtubeUrl?.message}
            required={false}
            disabled={false}
            name={'youtubeUrl'}
            label={'Youtube'}
            className='form-control'
          />
        </div>
      </RenderWhenGrid>
      <RenderWhenGrid visible={tiktok}>
        <div className='col-md-6'>
          <InputUrl
            control={control}
            errors={errors.tiktokUrl?.message}
            required={false}
            disabled={false}
            name={'tiktokUrl'}
            label={'TikTok'}
            className='form-control'
          />
        </div>
      </RenderWhenGrid>
    </>
  )
}

export default ContactCard
