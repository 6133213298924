import {ReactNode} from 'react'

interface Props {
  currentStep: number
  index: number
  content: ReactNode
  title: ReactNode
  description: ReactNode
  setCurrentStep: any
  selectable: boolean
  currentDefinitiveStep: number
}

const StepperItem = ({
  currentStep,
  index,
  content,
  title,
  description,
  setCurrentStep,
  selectable,
  currentDefinitiveStep
}: Props) => {
  const StepperContent = () => {
    return (
      <div
        key={index}
        className={`stepper-item mx-2 my-4 ${currentStep === index + 1 ? 'current' : ''}`}
        data-kt-stepper-element='nav'
      >
        <div className='stepper-line w-40px'></div>
        <div className='stepper-icon w-40px h-40px'>
          <i className='stepper-check fas fa-check'></i>
          <span className='stepper-number'>{index + 1}</span>
        </div>
        <div className='stepper-label'>
          <h3 className='stepper-title'>{title}</h3>
          <div className='stepper-desc'>{description}</div>
        </div>
      </div>
    )
  }

  if (selectable || currentDefinitiveStep === (index + 1))
    return (
      <a href='#' onClick={() => setCurrentStep(index + 1)}>
        <StepperContent />
      </a>
    )
  return <StepperContent />
}

export default StepperItem
