import {BaseResponse} from '../../../domain/models'
import {LoginRequest, SignupRequest, ForgotPasswordRequest } from '../../../domain/models/requests/authentication'
import { ChangePasswordRequest } from '../../../domain/models/requests/authentication/ChangePasswordRequest'
import { VerifyEmailRequest } from '../../../domain/models/requests/authentication/VerifyEmailRequest'
// import { AuthenticationFilterResponse } from '../../../domain/models/responses/authentication/AuthenticationFilterResponse'
import {LoginResponse} from '../../../domain/models/responses/authentication/LoginResponse'
import {UserApiRoutes} from '../../constants/api/v1'
import {Http} from '../../http/Http'
import {Api} from './Api'

export class AuthenticationApi implements Api {
  // async updateUser(request: Omit<SignupRequest, "id">):Promise<BaseResponse> {
  //   const res = await Http.post<SignupRequest>(AuthenticationApiRoutes.Update, request)
  //   return res
  // }
  // async signUp(request: Omit<SignupRequest, "id">):Promise<BaseResponse> {
  //   const res = await Http.post<SignupRequest>(AuthenticationApiRoutes.ForgotPassword, request)
  //   return res
  // }

  async forgotPassword(request:ForgotPasswordRequest):Promise<BaseResponse> {
    const res = await Http.post<ForgotPasswordRequest>(UserApiRoutes.ForgotPassword.toString(), request)
    return res
  }
  async verifyEmail(request:VerifyEmailRequest): Promise<BaseResponse> {
    const res = await Http.post<VerifyEmailRequest>(UserApiRoutes.VerifyEmail.toString(), request)
    return res
  }
  async changePassword(request: ChangePasswordRequest):Promise<BaseResponse> {
    const res = await Http.post<ChangePasswordRequest>(UserApiRoutes.ChangePassword.toString(), request)
    return res
  }
  // async filter(): Promise<AuthenticationFilterResponse> {
  //   const res = await Http.get(AuthenticationApiRoutes.Filter)
  //   return res
  // }
  // async getById(id: string): Promise<AuthenticationFilterResponse>{
  //   const res = await Http.get(`${AuthenticationApiRoutes.GetById}${id}`)
  //   return res
  // }
  // async getRefreshToken(): Promise<LoginResponse> {
  //   const res = await Http.get(AuthenticationApiRoutes.Me)
  //   return res
  // }

  async getRefreshToken(): Promise<LoginResponse> {
    const res = await Http.get(UserApiRoutes.RefreshToken)
    return res
  }
  async login(request: Omit<LoginRequest, 'id'>): Promise<LoginResponse> {
    const res = await Http.post<LoginRequest>(UserApiRoutes.Login.toString(), request)
    return res
  }
}
