import { convertEnumToOptionsWithCleanLabel } from "../utils"

enum GroupSizeEnum {
  Individual = 'Individual',
  SmallGroup25 = 'SmallGroup25',
  MediumGroup610 = 'MediumGroup610',
  LargeGroup1115 = 'LargeGroup1115',
  ExtralargeGroup16 = 'ExtralargeGroup16',
  SmallAudience30 = 'SmallAudience30',
  MediumAudience50 = 'MediumAudience50',
  LargeAudience100 = 'LargeAudience100',
  Others = 'Others',
}

type GroupSizeType = typeof GroupSizeEnum[keyof typeof GroupSizeEnum]

function getGroupSizeTypes(): GroupSizeType[] {
  return Object.values(GroupSizeEnum)
}

function printGroupSize(value: GroupSizeEnum): string | undefined {
  switch (value) {
    case GroupSizeEnum.Individual:
      return 'Individual'
    case GroupSizeEnum.SmallGroup25:
      return 'Small Group (2-5)'
    case GroupSizeEnum.MediumGroup610:
      return 'Medium Group (6-10)'
    case GroupSizeEnum.LargeGroup1115:
      return 'Large Group (11-15)'
    case GroupSizeEnum.ExtralargeGroup16:
      return 'Extra-Large Group (16+)'
    case GroupSizeEnum.SmallAudience30:
      return 'Small Audience (30+)'
    case GroupSizeEnum.MediumAudience50:
      return 'Medium Audience (50+)'
    case GroupSizeEnum.LargeAudience100:
      return 'Large Audience (100+)'
    case GroupSizeEnum.Others:
      return 'Others'
    default:
      return ''
  }
}

function getGroupSizeEnumEnumOptions() {
  return convertEnumToOptionsWithCleanLabel(GroupSizeEnum, printGroupSize)
}

export {getGroupSizeTypes, printGroupSize, GroupSizeEnum, getGroupSizeEnumEnumOptions}
