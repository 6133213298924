/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {useAuth} from '../../../../infrastructure/local/auth/AuthContextProvider'
import {LoginRequest} from '../../../../domain/models/requests/authentication'
import {useLogin} from '../../../../domain/usecase'
import {AuthenticationRepositoryImpl} from '../../../../infrastructure/remote/repositories/authentication/AuthenticationRepositoryImpl'
import {AuthenticationApi} from '../../../../infrastructure/remote/api'
import {HttpStatusCode, ResponseStatus} from '../../../../infrastructure/enums'
import {Auth, User} from '../../../../domain/entities'
import UserMapper from '../../../../domain/mappers/UserMapper'
import {useForm} from '../../../../infrastructure/deps/react-form'
import {InlineAlert, InputText} from '../../../components'
import Button from '../../../components/Button/Button'
import {BootstrapUI} from '../../../../domain/enums'
import {LoginSchema} from '../../../../domain/models/validations'
import {joiResolver} from '@hookform/resolvers/joi'
import {isEmptyObject} from '../../../../domain/utils'
import {da} from 'date-fns/locale'

interface IFormInput {
  username: string
  password: string
}

export function VerifyEmail() {
  //Default username and password. Remove in production
  const defaultValues: IFormInput = {
    username: '',
    password: '',
  }

  const {
    handleSubmit,
    control,
    formState: {errors},
  } = useForm<IFormInput>({
    defaultValues,
    resolver: joiResolver(LoginSchema),
  })

  const [status, setStatus] = useState('')
  const {setCurrentUser} = useAuth()
  const performLogin = useLogin(new AuthenticationRepositoryImpl(new AuthenticationApi()))
  const mapper = new UserMapper()

  const onSubmit = (formData: IFormInput) => {
    let request: LoginRequest = {
      username: formData.username,
      password: formData.password,
    }
    performLogin.mutate(request, {
      onSuccess: (response) => {
        const {data, status} = response
        if (status === HttpStatusCode.OK) {
          console.log(data?.token?.token)
          setCurrentUser(data)
        } else {
          setStatus('The login details are incorrect')
        }
      },
      onError: () => {
        setStatus('The login details are incorrect')
      },
    })
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InlineAlert value={status} />
      <div className='mb-4'>
        <InputText
          control={control}
          type='text'
          name={'username'}
          label={'Username'}
          errors={errors.username?.message}
          className='form-control mt-5'
        />
      </div>
      <div className='mb-4'>
        <InputText
          control={control}
          type='password'
          name={'password'}
          label={'Password'}
          errors={errors.password?.message}
          className='form-control mt-5'
        />
      </div>
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div></div>
        <a
          href='/metronic8/demo1/?page=authentication/layouts/corporate/reset-password'
          className='link-secondary'
        >
          Forgot Password ?
        </a>
      </div>
      <div className='d-grid mb-10'>
        <Button
          type='submit'
          loading={performLogin.isLoading}
          disabled={!isEmptyObject(errors) || performLogin.isLoading}
          theme={BootstrapUI.Dark}
          value={'Login'}
        />
      </div>
    </form>
  )
}
