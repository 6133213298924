import {FC} from 'react'
import {EducationClinicianProps} from '../../../../management/artist/props'
import ToggleButtonGroup from '../../../../../components/ToggleButtonGroup/ToggleButtonGroup'
import {
  convertEnumToOptions,
  convertEnumToOptionsWithCleanLabel,
} from '../../../../../../domain/utils'
import {
  TeacherClinicianTypeEnum,
  printTeacherClinicianType,
} from '../../../../../../domain/enums/TeacherClinicianTypeEnum'
import {YesNoEnum, YesNoHybridEnum, YesNoOnlyEnum} from '../../../../../../domain/enums/YesNoEnum'
import {InputText, InputTextArea, RenderWhenGrid, Title} from '../../../../../components'
import {
  ArtistSkillLevelEnum,
  printArtistSkillLevel,
} from '../../../../../../domain/enums/ArtistSkillLevelEnum'
import {StudentsEnum, printStudents} from '../../../../../../domain/enums/StudentsEnum'
import {
  TeacherAvailableTimeEnum,
  printTeacherAvailableTime,
} from '../../../../../../domain/enums/TeacherAvailableTimeEnum'
import CommunityAdditionalInfoCard from '../../../partials/CommunityAdditionalInfoCard'
import PayRateInfoCard from '../../../partials/PayRateInfoCard'
import {GroupSizeEnum, printGroupSize} from '../../../../../../domain/enums/GroupSizeEnum'
import {
  ArtistMusicLiteracyEnum,
  printArtistMusicLiteracy,
} from '../../../../../../domain/enums/ArtistMusicLiteracyEnum'
import LocationMultiSelectCard from '../../../../../components/LocationMultiSelectCard/LocationMultiSelectCard'
import AddressCard from '../../../partials/AddressCard'
const EducationClinicianForm: FC<EducationClinicianProps> = ({
  control,
  errors,
  watch,
  provinces,
}) => {
  const artistOnlineServicesOptions = convertEnumToOptions(YesNoEnum)
  const artistTeacherClinicianTypeOptions = convertEnumToOptions(YesNoEnum)
  const artistTeacherRecentCriminalRecordCheckOptions = convertEnumToOptions(YesNoEnum)

  const teacherClinicianYesNoOptions = convertEnumToOptions(YesNoOnlyEnum)
  const teacherClinicianYesNoHybridOptions = convertEnumToOptions(YesNoHybridEnum)
  const teacherClinicianTypeOptions = convertEnumToOptionsWithCleanLabel(
    TeacherClinicianTypeEnum,
    printTeacherClinicianType
  )
  const teacherClinicianSkillLevelOptions = convertEnumToOptionsWithCleanLabel(
    ArtistSkillLevelEnum,
    printArtistSkillLevel
  )
  const teacherClinicianStudentsOptions = convertEnumToOptionsWithCleanLabel(
    StudentsEnum,
    printStudents
  )

  const teacherClinicianGroupSizeOptions = convertEnumToOptionsWithCleanLabel(
    GroupSizeEnum,
    printGroupSize
  )

  const teacherClinicianAvailableTimeOptions = convertEnumToOptionsWithCleanLabel(
    TeacherAvailableTimeEnum,
    printTeacherAvailableTime
  )

  const teacherClinicianMusicLiterarcyOptions = convertEnumToOptionsWithCleanLabel(
    ArtistMusicLiteracyEnum,
    printArtistMusicLiteracy
  )
  const clinicianTeachsOnline = watch('teacherClinicianOnlineTeachingStatus')
  return (
    <>
      <div className='col-md-12 mt-5'>
        <ToggleButtonGroup
          name={'teacherClinicianType'}
          label={'Type'}
          type='exclusive'
          options={teacherClinicianTypeOptions}
          control={control}
        />
      </div>

      <div className='col-md-6'>
        <InputTextArea
          control={control}
          rows={4}
          name={'teacherClinicianFieldOfStudy'}
          required={false}
          disabled={false}
          label={'Fields of Study (List all, separated by a comma.)'}
          errors={errors.name?.message}
          className='form-control mt-5'
        />
      </div>

      <div className='col-md-6'>
        <InputTextArea
          control={control}
          rows={4}
          name={'teacherClinicianInstruments'}
          required={false}
          disabled={false}
          label={'Instrument(s) (List all that apply, separated by a comma)'}
          errors={errors.name?.message}
          className='form-control mt-5'
        />
      </div>

      <div className='col-md-12 mt-5'>
        <ToggleButtonGroup
          name={'teacherClinicianStudents'}
          label={'Students (Select all that apply)'}
          type='inclusive'
          options={teacherClinicianStudentsOptions}
          control={control}
        />
      </div>

      <div className='col-md-12 mt-5'>
        <ToggleButtonGroup
          name={'teacherClinicianSkillLevelOptions'}
          label={'Skill Level'}
          type='exclusive'
          options={teacherClinicianSkillLevelOptions}
          control={control}
        />
      </div>

      <div className='col-md-6'>
        <InputText
          control={control}
          name={'teacherClinicianYearsOfExperience'}
          required={false}
          disabled={false}
          label={'Years of experience as a Teacher, Coach, and Clinician.'}
          errors={errors.name?.message}
          className='form-control mt-5'
          type={'number'}
        />
      </div>

      <div className='col-md-12 mt-5'>
        <ToggleButtonGroup
          name={'teacherClinicianGroupSize'}
          label={'Group Size'}
          type='inclusive'
          options={teacherClinicianGroupSizeOptions}
          control={control}
        />
      </div>

      <div className='col-md-6 mt-5'>
        <ToggleButtonGroup
          name={'teacherClinicianAvailableTime'}
          label={'Available Time.'}
          type='inclusive'
          options={teacherClinicianAvailableTimeOptions}
          control={control}
        />
      </div>

      <div className='col-md-6 mt-5'>
        <ToggleButtonGroup
          name={'teacherClinicianMusicLiterarcy'}
          label={'Music Literacy'}
          type='exclusive'
          options={teacherClinicianMusicLiterarcyOptions}
          control={control}
        />
      </div>

      <CommunityAdditionalInfoCard control={control} errors={errors} />

      <div className='col-md-12 mt-5'>
        <Title type={'xxs'}>Venue Information</Title>
      </div>
      <div className='col-md-12 mt-5'>
        <ToggleButtonGroup
          name={'teacherClinicianOnlineTeachingStatus'}
          label={'Do you teach online?'}
          type='exclusive'
          options={teacherClinicianYesNoHybridOptions}
          control={control}
        />
      </div>

      <RenderWhenGrid
        visible={
          clinicianTeachsOnline === YesNoHybridEnum.No ||
          clinicianTeachsOnline === YesNoHybridEnum.Hybrid
        }
      >
        <div className='col-md-6'>
          <InputText
            control={control}
            name={'teacherClinicianVenueName'}
            required={false}
            disabled={false}
            label={'Location Name (Where do you teach out of?)'}
            errors={errors.name?.message}
            className='form-control mt-5'
            type={'number'}
          />
        </div>
        <div className='col-md-12 mt-5'>
          <AddressCard
            id={'teacher-clinican-eng-address'}
            label={'Address'}
            control={control}
            errors={errors}
            watch={watch}
            provinceList={provinces}
            provinceLabel={'Province'}
            province={true}
            addressLineOne={true}
            addressLineTwo={true}
            city={true}
            postalCode={true}
            addressPhone={false}
            addressLineOneLabel={'Address Line 1 (required)'}
            addressLineTwoLabel={'Address Line 2'}
            cityLabel={'City (required)'}
            postalCodeLabel={'Postal Code'}
            addressPhoneLabel={''}
          />
        </div>
        <div className='col-md-12 mt-5'>
          <ToggleButtonGroup
            name={'teacherClinicianVenueAccessibility'}
            label={'The venue is wheelchair accessible.'}
            type='exclusive'
            options={teacherClinicianYesNoOptions}
            control={control}
          />
        </div>
        <div className='col-md-6'>
          <InputTextArea
            control={control}
            rows={4}
            name={'teacherClinicianVenueEquipment'}
            required={false}
            disabled={false}
            label={'Supplied equipment. (Microphones, music stands, monitors, amps, etc.)'}
            errors={errors.name?.message}
            className='form-control mt-5'
          />
        </div>
      </RenderWhenGrid>

      <div className='col-md-12 mt-5'>
        <Title type={'xxs'}>Offer Information</Title>
      </div>
      <PayRateInfoCard control={control} errors={errors} rateQuestion='Minimum rate per HOUR?' />
    </>
  )
}

export default EducationClinicianForm
