import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue, PageTitle, WithChildren} from '../../infrastructure/deps/metronic/v2'
import {MasterLayout} from '../components/MasterLayout'
import {PageTitleSuffix} from '../constants/PageConstants'
import Onboarding from '../pages/onboarding/Onboarding'

const PrivateRoutes = () => {
  const Dashboard = lazy(() => import('../pages/dashboard/Index'))
  const Connect = lazy(() => import('../pages/connect/Index'))
  // const Onboarding = lazy(() => import('../pages/onboarding/Onboarding'))
  const Manage = lazy(() => import('../pages/management/Index'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route
          path='manage/*'
          element={
            <SuspensedView>
              <Manage />
            </SuspensedView>
          }
        />
        <Route
          path='dashboard'
          element={
            <SuspensedView>
              {/* <PageTitle>{PageTitleSuffix}- Dashboard</PageTitle> */}
              <Dashboard />
            </SuspensedView>
          }
        />
       <Route
          path='onboarding'
          element={
            <SuspensedView>
              <Onboarding />
            </SuspensedView>
          }
        /> 
        <Route
          path='connect/*'
          element={
            <SuspensedView>
              {/* <PageTitle>{PageTitleSuffix}- Connects</PageTitle> */}
              <Connect />
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
