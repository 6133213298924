import {getLength, isEmpty, removeProtocolPrefix} from '../../../domain/utils'
import {Control, Controller} from '../../../infrastructure/deps/react-form'

interface InputUrlProps {
  errors?: any
  label: string
  name: string
  className: string
  control: Control<any, any>
  required?: boolean
  disabled?: boolean
  showCounter?: boolean
}

const InputUrl = ({
  errors,
  label,
  name,
  className,
  control,
  required,
  disabled,
  showCounter = false,
}: InputUrlProps) => {
  return (
    <div className='form-group'>
      <label htmlFor={name}>{label}</label>
      <div className='input-group mb-3'>
        <div className='input-group-prepend'>
          <span className='input-group-text' id='basic-addon1'>
            https://
          </span>
        </div>
        {/* <label htmlFor={name}>{label}</label> */}
        <Controller
          render={({field: {ref, onChange, value}}) => (
            <>
              <input
                type={'text'}
                disabled={disabled}
                aria-label='URL'
                aria-describedby='basic-addon1'
                className={`${className} ${isEmpty(errors) ? '' : 'is-invalid'}`}
                onChange={(e) => onChange(removeProtocolPrefix(e.target.value))}
                value={value}
              />
            </>
          )}
          control={control}
          rules={{required: required}}
          name={name}
        />
        {!isEmpty(errors) ? <span className='error text-danger'>{errors}</span> : ''}
      </div>
    </div>
  )
}

export default InputUrl
