import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
// import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {Auth, User} from '../../../domain/entities'
import * as authHelper from './Auth'
import {WithChildren} from '../../deps/metronic/v2'
import {useRefreshToken} from '../../../domain/usecase'
import {AuthenticationRepositoryImpl} from '../../remote/repositories/authentication/AuthenticationRepositoryImpl'
import {AuthenticationApi} from '../../remote/api'
import {useToken} from '../../../domain/usecase/authentication/useToken'
import {getUserBySession} from '../../../domain/usecase/authentication/getUserBySession'

type AuthContextProps = {
  auth: Auth | undefined
  saveAuth: (auth: Auth | undefined) => void
  currentUser: any | undefined
  setCurrentUser: Dispatch<SetStateAction<any | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {logout, setCurrentUser} = useAuth()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  useEffect(() => {
    const requestUser = async () => {
      try {
        const {data} = await getUserBySession()
        if (data) {
          setCurrentUser(data)
        }
      } catch (error) {
        console.error(error)
        logout()
      } finally {
        setShowSplashScreen(false)
      }
    }
    requestUser()
  }, [])
  return showSplashScreen ? <div>Loading...</div> : <>{children}</>
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<Auth | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<any | undefined>()
  const saveAuth = (auth: Auth | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }
  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }
  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

export {AuthProvider, AuthInit, useAuth}
