import {ReactNode} from 'react'

interface Props {
  children: ReactNode
}

const H1 = ({children,...props}: Props) => {
  return <h1 {...props}>{children}</h1>
}

export default H1