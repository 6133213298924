import {createRoot} from 'react-dom/client'

import axiosInstance from './app/infrastructure/deps/axios'
import {Chart, registerables} from './app/infrastructure/deps/chartjs'
import {ReactQueryDevtools, queryClient, QueryClientProvider} from './app/infrastructure/deps/react-query'
import {MetronicI18nProvider} from './app/infrastructure/deps/metronic/v2'
import {AppRoutes} from './app/ui/routes/AppRoutes'
import {AuthProvider, setupAxios} from './app/infrastructure/local/auth'
import { ToastContainer } from 'react-toastify';

//css for metronic
import './_metronic/assets/fonticon/fonticon.css'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
//react date picker styling
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-time-picker/dist/TimePicker.css';

//for custom edits
import './app/ui/assets/css/custom.css'
//other css
import 'react-toastify/dist/ReactToastify.css';

setupAxios(axiosInstance)
Chart.register(...registerables)
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <AuthProvider>
          <ToastContainer/>
          <AppRoutes />
        </AuthProvider>
      </MetronicI18nProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}