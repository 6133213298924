import {FC, useState} from 'react'
import PageCard from '../../components/Card/PageCard'
import {Button, H5, P, RenderWhenGrid, Title} from '../../components'
import {BootstrapUI} from '../../../domain/enums'
import PageCardBody from '../../components/Card/PageCardBody'
import {useForm} from '../../../infrastructure/deps/react-form'
import ToggleButtonGroup from '../../components/ToggleButtonGroup/ToggleButtonGroup'
import {CategoryEnum, getCategoryTypeEnumOptions} from '../../../domain/enums/CategoryEnum'
import ArtistOnboardingForm from './forms/ArtistOnboardingForm'
import AgentOnboardingForm from './forms/AgentOnboardingForm'
import VenueOnboardingForm from './forms/VenueOnboardingForm'
import {createOnboardingRequest} from '../../utils/OnboardingMapper'
import {useUserOnboarding} from '../../../domain/usecase/user/useUserOnboarding'
import {UserRepositoryImpl} from '../../../infrastructure/remote/repositories/user/UserRepositoryImpl'
import {UserApi} from '../../../infrastructure/remote/api/UserApi'
import {isEmptyObject, isNullOrEmpty} from '../../../domain/utils'
import {ValidateOnboardingSchema} from '../../../domain/validations/ArtistSchema'
import {useAuth} from '../../../infrastructure/local/auth'
import PopUp from '../../components/PopUp/PopUp'
import {useNavigate} from 'react-router-dom'
import {useLocationGetFilter} from '../../../domain/usecase/location/useLocationGetFilter'
import {LocationRepositoryImpl} from '../../../infrastructure/remote/repositories/location/LocationRepositoryImpl'
import {LocationApi} from '../../../infrastructure/remote/api/LocationApi'
import {LocationFilter} from '../../../domain/entities/filters/LocationFilter'
import { HttpStatusCode } from '../../../infrastructure/enums'
import { ErrorNotification } from '../../utils/Notification'
import { UserApiRoutes } from '../../../infrastructure/constants/api/v1'
import { Http } from '../../../infrastructure/http'

const Onboarding: FC = () => {
  const locationFilter: LocationFilter = {
    type: 'province',
    provinceId: [],
    cityId: '',
    countryId: 1,
  }
  const locationFIlterProvider = useLocationGetFilter(new LocationRepositoryImpl(new LocationApi()))
  const getAllProvinces = locationFIlterProvider(locationFilter)

  const [isOnboardingComplete, setIsOnboardingComplete] = useState(false)
  const form = useForm({
    defaultValues: {
      accountType: '',
    },
  })
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    getValues,
    formState: {errors},
  } = form
  const navigate = useNavigate()
  const performCreate = useUserOnboarding(new UserRepositoryImpl(new UserApi()))
  const selectedaccountType = watch('accountType')

  const [searchKeyword, setSearchKeyword] = useState('')
  const {currentUser,logout} = useAuth()

  const onUseUserLogout = async () => {
    const url = UserApiRoutes.Logout
    const res = await Http.get(url)
    console.log('logout response', res)
    if (res.data) {
      if (res.status === HttpStatusCode.OK) {
        logout()
        return navigate('/', {replace: true})
      } else {
        ErrorNotification('onsubmit-log-error-failed', 'Could not log you out. Try again later')
      }
    }
  }
  const onSubmit = (formData: any) => {
    formData.initiatedBy = 'self'
    formData.uuid = currentUser?.user?.uuid //TODO:: get this from the currently signed in user. This is the user accounts master UUID
    const request = createOnboardingRequest(formData)
    const validateOnboardingRequest = ValidateOnboardingSchema(request)

    if (request !== undefined) {
      performCreate.mutate(request, {
        onSuccess: (response) => {
          const {status} = response
          if (status === HttpStatusCode.OK || status === HttpStatusCode.Created) {
            setIsOnboardingComplete(true)
          } else {
            ErrorNotification(
              'onsubmit-ry-error-failed',
              'Account creation did not complete successfully'
            )
          }
        },
        onError: () => {
          ErrorNotification(
            'onsubmit-ry-error-failed',
            'Account creation did not complete successfully'
          )
        },
      })
    }
  }
  return (
    <>
      <PageCard>
        <PageCardBody>
          <Title type='xxs'>Thank you for being a part of the community.</Title>
          <H5>Let’s get you signed up</H5>

          <div className='row'>
            <div className='col-md-12 mt-5'>
              <ToggleButtonGroup
                name={'accountType'}
                label={'Type'}
                type='exclusive'
                options={getCategoryTypeEnumOptions()}
                control={control}
              />
            </div>
          </div>
          <RenderWhenGrid visible={selectedaccountType === CategoryEnum.Artist}>
            <ArtistOnboardingForm
              control={control}
              watch={watch}
              errors={errors}
              provinces={getAllProvinces.data?.data}
            />
          </RenderWhenGrid>
          <RenderWhenGrid visible={selectedaccountType === CategoryEnum.Agent}>
            <AgentOnboardingForm
              control={control}
              watch={watch}
              errors={errors}
              provinces={getAllProvinces.data?.data}
            />
          </RenderWhenGrid>
          <RenderWhenGrid visible={selectedaccountType === CategoryEnum.Venue}>
            <VenueOnboardingForm
              control={control}
              watch={watch}
              errors={errors}
              provinces={getAllProvinces.data?.data}
            />
          </RenderWhenGrid>
          <div className='row'>
            <div className='col-md-12'>
              {!isEmptyObject(errors) ? (
                <span className='error text-danger'>
                  Complete the required fields before you proceed
                </span>
              ) : (
                ''
              )}
            </div>
            <div className='col-md-6'>
              <Button
                theme={BootstrapUI.Dark}
                type={'button'}
                value='Submit'
                disabled={isNullOrEmpty(selectedaccountType)}
                loading={performCreate.isLoading}
                onClick={handleSubmit(onSubmit)}
              ></Button>
            </div>
          </div>
          <PopUp
            isOpen={isOnboardingComplete}
            setIsOpen={setIsOnboardingComplete}
            title={'Account Creation Completed'}
            onClose={() => onUseUserLogout()}
            onProceed={() => onUseUserLogout()}
          >
            <H5>
              Congratulations! You have successfully created a new profile and joined our community.{' '}
            </H5>
            <P>
              Union Station is still in Beta, but you are free to update your profile to reflect any
              changes. We will keep you informed of any new features and updates.
            </P>
          </PopUp>
        </PageCardBody>
      </PageCard>
    </>
  )
}

export default Onboarding
