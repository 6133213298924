export enum BootstrapUI {
  Light="light",
  Secondary="secondary",
  Primary="primary",
  Success="success",
  Info="info",
  Warning="warning",
  Danger="danger",
  Dark="dark",
}
