import { isEmpty } from '../../../domain/utils'

interface Props {
  value: string
}

const InlineAlert = ({value}: Props) => {
  if(isEmpty(value)){
    return <></>
  }
  return (
    <>
      <div className='mb-lg-15 alert alert-danger'>
        <div className='alert-text font-weight-bold'>{value}</div>
      </div>
    </>
  )
}

export default InlineAlert
