import {ReactNode} from 'react'

interface Props {
  children: ReactNode
}

const P = ({children,...props}: Props) => {
  return <p {...props}>{children}</p>
}

export default P