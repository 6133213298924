import React, {memo} from 'react'
// import {isNullOrEmpty, truncateString} from '../../../infrastructure/'
import {Title, A, P, H1, H2, H3, H4, H5, H6} from '../index'

interface Props {
  value?: string | undefined
  type: 'xl' | 'lg' | 'md' | 'sm' | 'xs' | 'xxs'
  title: string
}

const InputLabel = ({value, type, title}: Props) => {
  const shorten = (value: string) => {
    // if (minValue === 0) {
    //   return value
    // } else {
    //   return truncateString(value, minValue)
    // }
  }

  const renderValue = (value: string) => {
    return value
    // if (!isNullOrEmpty(url)) {
    //   return (
    //     <A to={url}>
    //       {shorten(value)}
    //     </A>
    //   )
    // } else {
    //   return <P>{shorten(value)}</P>
    // }
  }
  const renderTitle = () => {
    if (type !== null || type !== undefined) {
      switch (type) {
        case 'xl':
          return <H1>{title}</H1>
        case 'lg':
          return <H2>{title}</H2>
        case 'md':
          return <H3>{title}</H3>
        case 'sm':
          return <H4>{title}</H4>
        case 'xs':
          return <H5>{title}</H5>
        case 'xxs':
          return <H6>{title}</H6>
        default:
          return title
      }
    }
    return title
  }
  return (
    <div>
      <div>{renderTitle()}</div>
      <div>{renderValue(value ? value : '')}</div>
    </div>
  )
}
export default InputLabel
