import React, {ReactNode, forwardRef, memo} from 'react'
interface Props {
  children: ReactNode
  visible?: Boolean | false
}
const Default = forwardRef(({children, visible}: Props) => {
  if (!visible) return <></>
  return <>{children}</>
})
export default memo(Default)
