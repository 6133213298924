import {getLength, isEmpty} from '../../../domain/utils'
import {Control, Controller} from '../../../infrastructure/deps/react-form'

interface InputTextProps {
  errors?: any
  label: string
  name: string
  className: string
  type: 'text' | 'password' | 'number' | 'email'
  control: Control<any, any>
  required?: boolean
  disabled?: boolean
  showCounter?: boolean
}

const InputText = ({
  errors,
  label,
  name,
  className,
  control,
  type,
  required,
  disabled,
  showCounter = false,
}: InputTextProps) => {
  return (
    <div className='form-floating'>
      <label htmlFor={name}>{label}</label>
      <Controller
        render={({field: {ref, onChange, value}}) => {
          if (type === 'number') {
            return (
              <>
                <input
                  type={type}
                  disabled={disabled}
                  className={`${className} ${isEmpty(errors) ? '' : 'is-invalid'}`}
                  onChange={onChange}
                  min={0}
                  maxLength={7}
                  value={value}
                />
                <span> {showCounter ? `${getLength(value)} characters` : ''}</span>
              </>
            )
          }
          return (
            <>
              <input
                type={type}
                disabled={disabled}
                className={`${className} ${isEmpty(errors) ? '' : 'is-invalid'}`}
                onChange={onChange}
                value={value}
              />
              <span> {showCounter ? `${getLength(value)} characters` : ''}</span>
            </>
          )
        }}
        control={control}
        rules={{required: required}}
        name={name}
      />
      {!isEmpty(errors) ? <span className='error text-danger'>{errors}</span> : ''}
    </div>
  )
}

export default InputText
