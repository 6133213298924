import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError} from 'axios'
import { BaseUrl } from '../../constants/api'


const axiosInstance: AxiosInstance = axios.create({
  baseURL: BaseUrl + "/v1/",
  // timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    Accept: 'text/plain',
  },
  withCredentials: true,
})

const getToken = () => {
  return ''
}

axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = getToken()
    if (token !== null && token !== '') {
      if (!config.headers) {
        config.headers = {}
      }
    }
    return config
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  (error: AxiosError) => {
    if (error.response) {
    } else if (error.request) {
    } else {
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
