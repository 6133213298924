import {Option} from '../../ui/interfaces/Option'

const RecordProducerStudioEngineerEnum = {
  RecordProducer: 'RecordProducer', 
  AudioEngineer: 'AudioEngineer',
  Mastering: 'MasteringEngineer',
}

type RecordProducerStudioEngineerEnumType =
  typeof RecordProducerStudioEngineerEnum[keyof typeof RecordProducerStudioEngineerEnum]

function getAllRecordProducerStudioEngineers(
  value: RecordProducerStudioEngineerEnumType
): RecordProducerStudioEngineerEnumType[] {
  const engineers = []
  engineers.push(RecordProducerStudioEngineerEnum.RecordProducer)
  engineers.push(RecordProducerStudioEngineerEnum.AudioEngineer)
  engineers.push(RecordProducerStudioEngineerEnum.Mastering)
  return engineers
}

function getRecordProducerStudioEngineerOptions(
): Option[] {
  const engineerOptions: Option[] = []
  engineerOptions.push({
    value: RecordProducerStudioEngineerEnum.RecordProducer,
    label: `${printRecordProducerEngineer(RecordProducerStudioEngineerEnum.RecordProducer)}`,
  })
  engineerOptions.push({
    value: RecordProducerStudioEngineerEnum.AudioEngineer,
    label: `${printRecordProducerEngineer(
      RecordProducerStudioEngineerEnum.AudioEngineer
    )}`,
  })
  engineerOptions.push({
    value: RecordProducerStudioEngineerEnum.Mastering,
    label: `${printRecordProducerEngineer(RecordProducerStudioEngineerEnum.Mastering)}`,
  })
  return engineerOptions
}

function printRecordProducerEngineer(value: RecordProducerStudioEngineerEnumType): string {
  switch (value) {
    case RecordProducerStudioEngineerEnum.RecordProducer:
      return 'Record Producer'
    case RecordProducerStudioEngineerEnum.AudioEngineer:
      return 'AudioEngineer'
    case RecordProducerStudioEngineerEnum.Mastering:
      return 'Mastering Engineer'
    default:
      return ''
      break
  }
}

const recordProducerStudioEngineerTypes = Object.keys(RecordProducerStudioEngineerEnum)

export {
  getAllRecordProducerStudioEngineers,
  getRecordProducerStudioEngineerOptions,
  printRecordProducerEngineer,
  recordProducerStudioEngineerTypes,
  RecordProducerStudioEngineerEnum,
}
