
enum RetailRentalRepairShopTypeEnum {
InstrumentSales = 'InstrumentSales',
InstrumentRentals = 'InstrumentRentals',
SoundSystemProAudioPaAndMusicTechSales = 'SoundSystemProAudioPaAndMusicTechSales',
SoundSystemProAudioPaAndMusicTechRentals = 'SoundSystemProAudioPaAndMusicTechRentals',
LuthierStringInstrumentRepairGuitarSetup = 'LuthierStringInstrumentRepairGuitarSetup',
AcousticPianoTuningRepair = 'AcousticPianoTuningRepair',
ElectronicsLightsAmpPedalsRepair = 'ElectronicsLightsAmpPedalsRepair',
}

type RetailRentalRepairShopTypeType = typeof RetailRentalRepairShopTypeEnum[keyof typeof RetailRentalRepairShopTypeEnum]

function getRetailRentalRepairShopTypeTypes(): RetailRentalRepairShopTypeType[] {
        return Object.values(RetailRentalRepairShopTypeEnum);
      } 



function printRetailRentalRepairShopType(value: RetailRentalRepairShopTypeEnum): string | undefined { 
switch (value) { 
       case RetailRentalRepairShopTypeEnum.InstrumentSales:
        return 'Instrument Sales';
       case RetailRentalRepairShopTypeEnum.InstrumentRentals:
        return 'Instrument Rentals';
       case RetailRentalRepairShopTypeEnum.SoundSystemProAudioPaAndMusicTechSales:
        return 'Sound System, Pro Audio (PA), and Music Tech Sales';
       case RetailRentalRepairShopTypeEnum.SoundSystemProAudioPaAndMusicTechRentals:
        return 'Sound System, Pro Audio (PA), and Music Tech Rentals';
       case RetailRentalRepairShopTypeEnum.LuthierStringInstrumentRepairGuitarSetup:
        return 'Luthier / String Instrument Repair / Guitar Setup';
       case RetailRentalRepairShopTypeEnum.AcousticPianoTuningRepair:
        return 'Acoustic Piano Tuning / Repair';
       case RetailRentalRepairShopTypeEnum.ElectronicsLightsAmpPedalsRepair:
        return 'Electronics / Lights / Amp / Pedals Repair';
      default:
    return "";
        }
     }


export { getRetailRentalRepairShopTypeTypes, printRetailRentalRepairShopType, RetailRentalRepairShopTypeEnum }; 
