import {FC} from 'react'
import {RetailRentalRepairShopFormProps} from '../../../../management/venue/props'
import {convertEnumToOptionsWithCleanLabel} from '../../../../../../domain/utils'
import {Title} from '../../../../../components'
import CheckBoxGroup from '../../../../../components/CheckBoxGroup/CheckBoxGroup'
import {
  RetailRentalRepairShopTypeEnum,
  printRetailRentalRepairShopType,
} from '../../../../../../domain/enums/RetailRentalRepairShopTypeEnum'
import AddressCard from '../../../partials/AddressCard'
import VenueAdditionalInfoCard from '../../../partials/VenueAdditionalInfoCard'

const RetailRentalRepairShopForm: FC<RetailRentalRepairShopFormProps> = ({
  control,
  errors,
  watch,
  provinces,
}) => {
  const retailRentalRepairShopTypeOptions = convertEnumToOptionsWithCleanLabel(
    RetailRentalRepairShopTypeEnum,
    printRetailRentalRepairShopType
  )

  return (
    <>
      <div className='col-md-12'>
        <CheckBoxGroup
          name={'retailRentalRepairShopType'}
          label={'subType'}
          // errors={errors.musicVenueType?.message}
          options={retailRentalRepairShopTypeOptions}
          // className={"'"}
          control={control}
          className={'mb-5'}
        />
      </div>

      <AddressCard
        id={'music-vn-address'}
        label={'Venue Address'}
        control={control}
        errors={errors}
        watch={watch}
        provinceList={provinces}
        provinceLabel={'Province'}
        province={true}
        addressLineOne={true}
        addressLineTwo={true}
        city={true}
        postalCode={true}
        addressPhone={true}
        addressLineOneLabel={'Address Line 1 (required)'}
        addressLineTwoLabel={'Address Line 2'}
        cityLabel={'City (required)'}
        postalCodeLabel={'Postal Code'}
        addressPhoneLabel={'Venue Phone'}
      />

      <VenueAdditionalInfoCard control={control} errors={errors} />
    </>
  )
}

export default RetailRentalRepairShopForm
