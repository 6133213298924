import {FC} from 'react'
import {MusicVenueFormProps} from '../../../../management/venue/props'
import {convertEnumToOptionsWithCleanLabel} from '../../../../../../domain/utils'
import ToggleButtonGroup from '../../../../../components/ToggleButtonGroup/ToggleButtonGroup'
import {H5, InputTextArea, Title} from '../../../../../components'
import CheckBoxGroup from '../../../../../components/CheckBoxGroup/CheckBoxGroup'
import {
  MusicVenueTypeEnum,
  formatVenueType,
} from '../../../../../../domain/enums/MusicVenueTypeEnum'
import {
  VenueCapacityEnum,
  printVenueCapacity,
} from '../../../../../../domain/enums/VenueCapacityEnum'
import {
  VenueStageSuppliesEnum,
  getVenueStageSuppliesEnumOptions,
  printVenueStageSupplies,
} from '../../../../../../domain/enums/VenueStageSuppliesEnum'
import {
  VenueFoodOptionsEnum,
  printVenueFoodOptions,
} from '../../../../../../domain/enums/VenueFoodOptionsEnum'
import {VenueExtrasEnum, printVenueExtras} from '../../../../../../domain/enums/VenueExtrasEnum'
import PayRateInfoCard from '../../../partials/PayRateInfoCard'
import AddressCard from '../../../partials/AddressCard'
import VenueAdditionalInfoCard from '../../../partials/VenueAdditionalInfoCard'

const MusicVenueForm: FC<MusicVenueFormProps> = ({control, errors, watch, provinces}) => {
  const venueTypeOptions = convertEnumToOptionsWithCleanLabel(MusicVenueTypeEnum, formatVenueType)
  const venueCapacityOptions = convertEnumToOptionsWithCleanLabel(
    VenueCapacityEnum,
    printVenueCapacity
  )
  const venueStageSupplyOptions = convertEnumToOptionsWithCleanLabel(
    VenueStageSuppliesEnum,
    printVenueStageSupplies
  )
  const venueFoodOptions = convertEnumToOptionsWithCleanLabel(
    VenueFoodOptionsEnum,
    printVenueFoodOptions
  )
  const venueExtrasOptions = convertEnumToOptionsWithCleanLabel(VenueExtrasEnum, printVenueExtras)

  return (
    <>
      <div className='col-md-12'>
        <ToggleButtonGroup
          name={'subType'}
          label={'Type'}
          type='inclusive'
          options={venueTypeOptions}
          control={control}
        />
      </div>

      <AddressCard
        id={'music-vn-address'}
        label={'Venue Address'}
        control={control}
        errors={errors}
        watch={watch}
        provinceList={provinces}
        provinceLabel={'Province'}
        province={true}
        addressLineOne={true}
        addressLineTwo={true}
        city={true}
        postalCode={true}
        addressPhone={true}
        addressLineOneLabel={'Address Line 1 (required)'}
        addressLineTwoLabel={'Address Line 2'}
        cityLabel={'City (required)'}
        postalCodeLabel={'Postal Code'}
        addressPhoneLabel={'Venue Phone'}
      />

      <div className='col-md-12 mt-5'>
        <ToggleButtonGroup
          name={'capacity'}
          label={'Capacity (How many people fit in your venue)'}
          type='exclusive'
          options={venueCapacityOptions}
          control={control}
        />
      </div>

      <div className='col-md-12'>
        <CheckBoxGroup
          name={'suppliedEquipment'}
          label={'Do you supply the following?'}
          errors={errors.venueSuppliedEquipment?.message}
          options={getVenueStageSuppliesEnumOptions()}
          // TODO: New enums for venue equipment (ie Stage, sound system, etc)
          // className={"'"}
          control={control}
          className={'mb-5'}
        />
      </div>

      <div className='col-md-6 mt-5'>
        <H5>The Venue's backline and gear</H5>
        <InputTextArea
          control={control}
          rows={4}
          name={'backlineGear'}
          required={false}
          disabled={false}
          label={'What music equipment is supplied?'}
          errors={errors.name?.message}
          className='form-control mt-5'
          placeholder='Drums, amps, pedals, etc'
        />
      </div>

      <div className='col-md-6 mt-6'>
        <CheckBoxGroup
          name={'foodOptions'}
          label={'Select all that apply.'}
          options={venueFoodOptions}
          // className={"'"}
          control={control}
          className={'mb-5'}
        />
      </div>

      <div className='col-md-6'>
        <CheckBoxGroup
          name={'extras'}
          label={'Extras'}
          options={venueExtrasOptions}
          // className={"'"}
          control={control}
          className={'mb-5'}
        />
      </div>

      <VenueAdditionalInfoCard control={control} errors={errors} />
      <div className='col-md-12 mt-5'>
        <Title type={'xxs'}>Rental Information</Title>
      </div>
      <PayRateInfoCard control={control} errors={errors} rateQuestion='Minimum rate HOUR.' />
    </>
  )
}

export default MusicVenueForm
