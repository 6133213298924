import {convertEnumToOptionsWithCleanLabel} from '../utils'

const CategoryEnum = {
  Artist: 'Artist',

  Venue: 'Venue',
  Agent: 'Agent',
}

function getCategoryTypes() {
  const output = []
  output.push(CategoryEnum.Artist)

  output.push(CategoryEnum.Venue)

  output.push(CategoryEnum.Agent)
  return output
}
const CategoryType = Object.keys(CategoryEnum)

function printCategory(value: string | null) {
  switch (value) {
    case CategoryEnum.Artist:
      return 'Artist'
    case CategoryEnum.Agent:
      return 'Agent'
    case CategoryEnum.Venue:
      return 'Venue'
    default:
      break
  }
}

function lowercaseCategory(value: string | null) {
  switch (value) {
    case 'artist':
      return 'Artist'
      case 'agent':
      return 'Agent'
      case 'venue':
      return 'Venue'
    default:
      break
  }
}

function getCategoryTypeEnumOptions() {
  return convertEnumToOptionsWithCleanLabel(CategoryEnum, printCategory)
}

export {getCategoryTypes, printCategory, CategoryEnum, getCategoryTypeEnumOptions, lowercaseCategory}
