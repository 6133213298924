import {ReactNode} from 'react'
interface Props {
  children: ReactNode
}
const PageCard = ({children}: Props) => {
  return (
    <>
      <div className='card card-custom'>{children}</div>
    </>
  )
}
export default PageCard
