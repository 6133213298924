import {Option} from '../../ui/interfaces/Option'

const MusicVenueTypeEnum = {
  LiveMusicVenue: 'LiveMusicVenue',
  RestaurantBarPub: 'RestaurantBarPub',
  NightClubLounge: 'NightClubLounge',
  CafeGallery: 'CafeGallery',
  CommunityCentreHall: 'CommunityCentreHall',
  TheatreAuditorium: 'TheatreAuditorium',
  JazzBluesFolkClub: 'JazzBluesFolkClub',
  FestivalFairJamboree: 'FestivalFairJamboree',
  ConferenceCentreHotelBanquetRoom: 'ConferenceCentreHotelBanquetRoom',
  CollegeUniversitySchool: 'CollegeUniversitySchool',
  LGBTQ2SVenue: 'LGBTQ2SVenue',
  Casino: 'Casino',
  PrivateHouseVenue: 'PrivateHouseVenue',
  FairgroundOutdoorVenue: 'FairgroundOutdoorVenue',
  FarmersMarket: 'FarmersMarket',
  LegionVeteransOrganization: 'LegionVeteransOrganization',
  ChurchReligiousSpace: 'ChurchReligiousSpace',
  ArenaStadium: 'ArenaStadium',
  RecordingStudioVariousStudio: 'RecordingStudioVariousStudio',
  OnlineStreamingVirtualReality: 'OnlineStreamingVirtualReality',
  WeddingLocation: 'WeddingLocation',
  OpenMicLocation: 'OpenMicLocation',
}

type MusicVenueTypeEnumType = typeof MusicVenueTypeEnum[keyof typeof MusicVenueTypeEnum]

function getAllMusicVenueTypes(): MusicVenueTypeEnumType[] {
  const venueTypes = Object.values(MusicVenueTypeEnum)
  return venueTypes
}

function getMusicVenueTypeOptions(): Option[] {
  const venueOptions: Option[] = []
  for (const venueType of Object.values(MusicVenueTypeEnum)) {
    venueOptions.push({value: venueType, label: `${formatVenueType(venueType)}`})
  }
  return venueOptions
}

function formatVenueType(value: MusicVenueTypeEnumType): string {
  switch (value) {
    case MusicVenueTypeEnum.LiveMusicVenue:
      return 'Live Music Venue'
    case MusicVenueTypeEnum.RestaurantBarPub:
      return 'Restaurant / Bar / Pub'
    case MusicVenueTypeEnum.NightClubLounge:
      return 'Night Club / Lounge'
    case MusicVenueTypeEnum.CafeGallery:
      return 'Cafe / Gallery'
    case MusicVenueTypeEnum.CommunityCentreHall:
      return 'Community Centre / Hall'
    case MusicVenueTypeEnum.TheatreAuditorium:
      return 'Theatre / Auditorium'
    case MusicVenueTypeEnum.JazzBluesFolkClub:
      return 'Jazz / Blues / Folk Club'
    case MusicVenueTypeEnum.FestivalFairJamboree:
      return 'Festival / Fair / Jamboree'
    case MusicVenueTypeEnum.ConferenceCentreHotelBanquetRoom:
      return 'Conference Centre / Hotel / Banquet Room'
    case MusicVenueTypeEnum.CollegeUniversitySchool:
      return 'College / University / School'
    case MusicVenueTypeEnum.LGBTQ2SVenue:
      return 'LGBTQ2S+ Venue'
    case MusicVenueTypeEnum.Casino:
      return 'Casino'
    case MusicVenueTypeEnum.PrivateHouseVenue:
      return 'Private Residence'
    case MusicVenueTypeEnum.FairgroundOutdoorVenue:
      return 'Fairground / Outdoor Venue'
    case MusicVenueTypeEnum.FarmersMarket:
      return 'Farmer’s Market'
    case MusicVenueTypeEnum.LegionVeteransOrganization:
      return 'Legion / Veterans Organization'
    case MusicVenueTypeEnum.ChurchReligiousSpace:
      return 'Church / Religious Space'
    case MusicVenueTypeEnum.ArenaStadium:
      return 'Arena / Stadium'
    case MusicVenueTypeEnum.RecordingStudioVariousStudio:
      return 'Recording Studio / Various Studio'
    case MusicVenueTypeEnum.OnlineStreamingVirtualReality:
      return 'Online / Streaming / Virtual Reality'
    case MusicVenueTypeEnum.WeddingLocation:
      return 'Wedding Location'
    case MusicVenueTypeEnum.OpenMicLocation:
      return 'Open Mic Location (does not pay)'
    default:
      return ''
  }
}

const musicVenueTypes = Object.keys(MusicVenueTypeEnum)

export {
  getAllMusicVenueTypes,
  getMusicVenueTypeOptions,
  formatVenueType,
  musicVenueTypes,
  MusicVenueTypeEnum,
}
