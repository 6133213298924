/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {useAuth} from '../../../../infrastructure/local/auth/AuthContextProvider'
import {LoginRequest} from '../../../../domain/models/requests/authentication'
import {useLogin} from '../../../../domain/usecase'
import {AuthenticationRepositoryImpl} from '../../../../infrastructure/remote/repositories/authentication/AuthenticationRepositoryImpl'
import {AuthenticationApi} from '../../../../infrastructure/remote/api'
import {HttpStatusCode, ResponseStatus} from '../../../../infrastructure/enums'
import {Auth, User} from '../../../../domain/entities'
import UserMapper from '../../../../domain/mappers/UserMapper'
import {useForm} from '../../../../infrastructure/deps/react-form'
import {H5, InlineAlert, InputText, P, RenderWhenGrid} from '../../../components'
import Button from '../../../components/Button/Button'
import {BootstrapUI} from '../../../../domain/enums'
import {LoginSchema} from '../../../../domain/models/validations'
import {joiResolver} from '@hookform/resolvers/joi'
import {isEmpty, isEmptyObject, isNullOrEmpty} from '../../../../domain/utils'
import {da} from 'date-fns/locale'
import {ChangePasswordSchema} from '../../../../domain/models/validations/ChangePassword'
import {ChangePasswordRequest} from '../../../../domain/models/requests/authentication/ChangePasswordRequest'
import {useChangePassword} from '../../../../domain/usecase/authentication/useChangePassword'
import {useNavigate, useSearchParams} from 'react-router-dom'
import PopUp from '../../../components/PopUp/PopUp'

interface IFormInput {
  password: string
  confirmPassword: string
}

export function ChangePassword() {
  const [searchParams, setSearchParams] = useSearchParams()
  const changePasswordUid = searchParams.get('ruid')
  const [isPasswordResetSuccess, setIsPasswordResetSuccess] = useState(false)
  const navigate = useNavigate()
  //Default username and password. Remove in production
  // const getFilter = useChangePassword

  const defaultValues: IFormInput = {
    password: '',
    confirmPassword: '',
  }

  const {
    handleSubmit,
    control,
    formState: {errors},
  } = useForm<IFormInput>({
    defaultValues,
    resolver: joiResolver(ChangePasswordSchema),
  })

  const [status, setStatus] = useState('')
  const {setCurrentUser} = useAuth()
  const performChangePassword = useChangePassword(
    new AuthenticationRepositoryImpl(new AuthenticationApi())
  )
  const mapper = new UserMapper()

  const onSubmit = (formData: IFormInput) => {
    let request: ChangePasswordRequest = {
      initiated_by: 'email',
      uuid: changePasswordUid == null || changePasswordUid == undefined ? '' : changePasswordUid,
      password: formData.password,
    }

    if (formData.confirmPassword !== formData.password) {
      setStatus('The provided passwords do not match.')
    } else {
      performChangePassword.mutate(request, {
        onSuccess: (response) => {
          const {data, status} = response
          if (status === HttpStatusCode.OK) {
            setIsPasswordResetSuccess(true)
          } else {
            setStatus('The password change details are incorrect')
          }
        },
        onError: () => {
          setStatus('Could not update your password')
        },
      })
    }
  }

  console.log('errors', errors)
  return (
    <>
      <RenderWhenGrid visible={isEmpty(changePasswordUid)}>
        <InlineAlert value={'The password reset link is expired or invalid'} />
        <div className='d-grid mb-10'>
          <Button
            type='submit'
            onClick={() => navigate('/auth/forgot-password')}
            theme={BootstrapUI.Dark}
            value={'Reset Password'}
          />
        </div>
      </RenderWhenGrid>
      <RenderWhenGrid visible={!isEmpty(changePasswordUid)}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InlineAlert value={status} />
          <div className='mb-4'>
            <InputText
              control={control}
              type='password'
              name={'password'}
              label={'New Password'}
              errors={errors.password?.message}
              className='form-control mt-5'
            />
          </div>
          <div className='mb-4'>
            <InputText
              control={control}
              type='password'
              name={'confirmPassword'}
              label={'Confirm New Password'}
              errors={errors.confirmPassword?.message}
              className='form-control mt-5'
            />
          </div>
          <div className='d-grid mb-10'>
            <Button
              type='submit'
              loading={performChangePassword.isLoading}
              //disabled={!isEmptyObject(errors) || performLogin.isLoading}
              disabled={performChangePassword.isLoading}
              theme={BootstrapUI.Dark}
              value={'Change Password'}
            />
          </div>
        </form>
        <PopUp
          isOpen={isPasswordResetSuccess}
          setIsOpen={setIsPasswordResetSuccess}
          title={'Password Updated'}
          onClose={() => navigate('/auth')}
          onProceed={() => navigate('/auth')}
        >
          <H5>Password updated Successfully!</H5>
          <P>The password for your account was Successfully updated. Proceed to login</P>
        </PopUp>
      </RenderWhenGrid>
    </>
  )
}
