import {convertEnumToOptionsWithCleanLabel} from '../utils'

const VenueEnum = {
  Music: 'Music',
  // Festivals: 'Festivals',
  RecordingStudioRehearsal: 'RecordingStudioRehearsal',
  SchoolAcademyLesson: 'SchoolAcademyLesson',
  RetailRentalRepairShop: 'RetailRentalRepairShop',
}

type VenueEnumType = typeof VenueEnum[keyof typeof VenueEnum]

function getVenueTypes(value: VenueEnumType): VenueEnumType[] {
  const output = []
  output.push(VenueEnum.Music)
  // output.push(VenueEnum.Festivals)
  output.push(VenueEnum.SchoolAcademyLesson)
  output.push(VenueEnum.RecordingStudioRehearsal)
  output.push(VenueEnum.RetailRentalRepairShop)
  return output
}
const venueTypes = Object.keys(VenueEnum)

function printVenueType(value: VenueEnumType): string {
  switch (value) {
    case VenueEnum.Music:
      return 'Music Venues'
    // case VenueEnum.Festivals:
    //   return 'Festivals / Popups'
    case VenueEnum.RetailRentalRepairShop:
      return 'Retail / Rental / Repair Shops'
    case VenueEnum.SchoolAcademyLesson:
      return 'Schools / Academies / Lesson Spaces'
    case VenueEnum.RecordingStudioRehearsal:
      return 'Recording Studio or Rehearsals'
    default:
      return 'N/A'
      break
  }
}

function getVenueTypeEnumOptions() {
  return convertEnumToOptionsWithCleanLabel(VenueEnum, printVenueType)
}

export {getVenueTypes, venueTypes, VenueEnum, printVenueType, getVenueTypeEnumOptions}
