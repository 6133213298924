import {Option} from '../../ui/interfaces/Option'

const EventManagerTechnicianEnum = {
  ProductionManager: 'ProductionManager',
  ProductionAssistant: 'ProductionAssistant',
  VenueManager: 'VenueManager',
  StageManager: 'StageManager',
  FOHEngineer: 'FOHEngineer',
  MonitorsInEarTechnician: 'MonitorsInEarTechnician',
  BacklineTechnician: 'BacklineTechnician',
  GuitarTechnician: 'GuitarTechnician',
  DrumTechnician: 'DrumTechnician',
  GeneralStageTechnician: 'GeneralStageTechnician',
  Rigger: 'Rigger',
  LightingLaserTechnician: 'LightingLaserTechnician',
  FollowSpotTechnician: 'FollowSpotTechnician',
}

type EventManagerTechnicianEnumType =
  typeof EventManagerTechnicianEnum[keyof typeof EventManagerTechnicianEnum]

function getAllEventManagerTechnicianOptions(
  value: EventManagerTechnicianEnumType
): EventManagerTechnicianEnumType[] {
  const allEventManagerTechnician = Object.values(EventManagerTechnicianEnum)
  return allEventManagerTechnician
}

function getEventManagerTechnicianOptions(): Option[] {
  const options: Option[] = []
  Object.entries(EventManagerTechnicianEnum).forEach(([key, value]) => {
    options.push({value: value, label: `${printEventManagerTechnicianType(value)}`})
  })
  return options
}

function printEventManagerTechnicianType(value: EventManagerTechnicianEnumType): string {
  switch (value) {
    case EventManagerTechnicianEnum.ProductionManager:
      return 'Production Manager'
    case EventManagerTechnicianEnum.ProductionAssistant:
      return 'Production Assistant'
    case EventManagerTechnicianEnum.VenueManager:
      return 'Venue Manager'
    case EventManagerTechnicianEnum.StageManager:
      return 'Stage Manager'
    case EventManagerTechnicianEnum.FOHEngineer:
      return 'Front-of-House (FOH) Engineer'
    case EventManagerTechnicianEnum.MonitorsInEarTechnician:
      return 'Monitors/In-Ear Monitors Technician'
    case EventManagerTechnicianEnum.BacklineTechnician:
      return 'Backline Technician'
    case EventManagerTechnicianEnum.GuitarTechnician:
      return 'Guitar Technician'
    case EventManagerTechnicianEnum.DrumTechnician:
      return 'Drum Technician'
    case EventManagerTechnicianEnum.GeneralStageTechnician:
      return 'General Stage Technician'
    case EventManagerTechnicianEnum.Rigger:
      return 'Rigger'
    case EventManagerTechnicianEnum.LightingLaserTechnician:
      return 'Lighting/Laser Technician'
    case EventManagerTechnicianEnum.FollowSpotTechnician:
      return 'Follow-Spot Technician'
    default:
      return ''
  }
}

const eventManagerTechnicianRoles = Object.keys(EventManagerTechnicianEnum)

export {
  getAllEventManagerTechnicianOptions,
  getEventManagerTechnicianOptions,
  printEventManagerTechnicianType,
  eventManagerTechnicianRoles,
  EventManagerTechnicianEnum,
}
