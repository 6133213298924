import {FC} from 'react'
import {SessionInstrumentalistFormProps} from '../../../../management/artist/props'
import {YesNoEnum} from '../../../../../../domain/enums/YesNoEnum'
import {
  convertEnumToOptions,
  convertEnumToOptionsWithCleanLabel,
} from '../../../../../../domain/utils'
import ToggleButtonGroup from '../../../../../components/ToggleButtonGroup/ToggleButtonGroup'
import {
  ArtistSkillLevelEnum,
  printArtistSkillLevel,
} from '../../../../../../domain/enums/ArtistSkillLevelEnum'
import {
  SessionArtistInstrumentalistEnum,
  printInstrumentalist,
} from '../../../../../../domain/enums/SessionArtistInstrumentalistEnum'
import {AutoCompleteMultiple, InputText, InputTextArea, Title} from '../../../../../components'
import CommunityAdditionalInfoCard from '../../../partials/CommunityAdditionalInfoCard'
import PayRateInfoCard from '../../../partials/PayRateInfoCard'
import {
  ArtistMusicLiteracyEnum,
  printArtistMusicLiteracy,
} from '../../../../../../domain/enums/ArtistMusicLiteracyEnum'
import {GenresEnum, printGenres} from '../../../../../../domain/enums/GenresEnum'
import {Option} from '../../../../../interfaces/Option'
import LocationMultiSelectCard from '../../../../../components/LocationMultiSelectCard/LocationMultiSelectCard'

const SessionInstrumentalistForm: FC<SessionInstrumentalistFormProps> = ({control, errors, watch, provinces}) => {
  const artistSessionInstrumentsOptions = convertEnumToOptions(YesNoEnum)
  const artistSessionSkillsOptions = convertEnumToOptions(YesNoEnum)

  const sessionInstrumentalistSkillLevelOptions = convertEnumToOptionsWithCleanLabel(
    ArtistSkillLevelEnum,
    printArtistSkillLevel
  )

  const sessionInstrumentalistTypesOptions = convertEnumToOptionsWithCleanLabel(
    SessionArtistInstrumentalistEnum,
    printInstrumentalist
  )

  const sessionInstrumentalistLiterarcyOptions = convertEnumToOptionsWithCleanLabel(
    ArtistMusicLiteracyEnum,
    printArtistMusicLiteracy
  )
  const genresOptions = convertEnumToOptionsWithCleanLabel(GenresEnum, printGenres)

  return (
    <>
      <div className='col-md-12 mt-5'>
        <ToggleButtonGroup
          name={'sessionInstrumentalistTypes'}
          label={'Type'}
          type='exclusive'
          options={sessionInstrumentalistTypesOptions}
          control={control}
        />
      </div>
      <div className='col-md-12'>
        <InputTextArea
          control={control}
          rows={4}
          name={'sessionInstrumentalistInstruments'}
          required={false}
          disabled={false}
          label={'Instrument(s) List all, separated by a comma'}
          errors={errors.name?.message}
          className='form-control mt-5'
        />
      </div>

      <div className='col-md-12 mt-5'>
        <ToggleButtonGroup
          name={'artistSessionSkills'}
          label={'Skill Level'}
          type='inclusive'
          options={sessionInstrumentalistSkillLevelOptions}
          control={control}
        />
      </div>

      <div className='col-md-12 mt-5'>
        <ToggleButtonGroup
          name={'sessionInstrumentalistMusicLiteracy'}
          label={'Music Literacy'}
          type='exclusive'
          options={sessionInstrumentalistLiterarcyOptions}
          control={control}
        />
      </div>

      <div className='col-md-6 mt-5'>
        <AutoCompleteMultiple
          label={'Genre(s) (List all that apply, separated by a comma)'}
          control={control}
          options={genresOptions}
          name={'genres'}
          errors={errors.roles?.message}
          getOptionLabel={function (option: Option): string {
            return option.label
          }}
          getOptionValue={function (option: Option): string {
            return option.value
          }}
        />
      </div>

      <div className='col-md-6 mt-5'>
        <InputText
          control={control}
          errors={errors.yearsOfExperienceAsPerformerBand?.message}
          required={false}
          disabled={false}
          name={'sessionInstrumentalistYearsOfExperience'}
          label={'Years of Experience as a Session Artist/Instrumentalist'}
          className='form-control'
          type={'number'}
        />
      </div>

      <CommunityAdditionalInfoCard control={control} errors={errors} />
      <div className='col-md-12 mt-5'>
        <Title type={'xxs'}>Offer Information</Title>
      </div>
      <PayRateInfoCard
        control={control}
        errors={errors}
        rateQuestion='Minimum rate per SET or SERVICE.'
      />
    </>
  )
}

export default SessionInstrumentalistForm
