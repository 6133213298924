
import {FC, lazy} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../pages/errors/ErrorsPage'
import {Logout, AuthPage, Login} from '../pages/authentication'
import {Design} from '../pages/design'

import {useAuth} from '../../infrastructure/local/auth'
import {App} from '../../App'
import { StaticPage } from '../pages/static'
const Landing = lazy(() => import('../pages/landing/index'))
const Join = lazy(() => import('../pages/join/Join'))
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  //Reload here

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='docs/design-guide' element={<Design/>} />
          <Route path='join' element={<Join />} />
          <Route path='p/*' element={<StaticPage />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
            
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='landing' element={<Landing />} />
  
              <Route path='*' element={<Navigate to='/landing' />} />
              {/* <Route path='*' element={<Navigate to='/auth' />} /> */}
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
