import Select from 'react-select'
import {Control, Controller} from '../../../infrastructure/deps/react-form'
import {isEmpty} from '../../../domain/utils'

interface Option {
  value: string
  label: string
}

interface Props<T> {
  name: string
  className?: string
  label?: string
  errors?: any
  options: T[]
  control: Control<any, any>
  getOptionLabel: (option: T) => string
  getOptionValue: (option: T) => string
  required?: boolean
}


const customStyles = {
  option: (defaultStyles: any, state: any) => ({
    // You can log the defaultStyles and state for inspection
    // You don't need to spread the defaultStyles
    ...defaultStyles,
    color: state.isSelected ? "#212529" : "#fff",
    backgroundColor: state.isSelected ? "#a0a0a0" : "#212529",
  }),

  control: (defaultStyles: any) => ({
    ...defaultStyles,
    // Notice how these are all CSS properties
    backgroundColor: "#212529",
    padding: "10px",
    border: "none",
    boxShadow: "none",
  }),
  singleValue: (defaultStyles: any) => ({ ...defaultStyles, color: "#fff" }),
};

const AutoComplete = ({
  name,
  className,
  options,
  control,
  label,
  getOptionLabel,
  getOptionValue,
  required = false,
  errors,
}: Props<Option>) => {
  const autoCompleteOptions: Option[] = options.map((option) => ({
    value: getOptionValue(option),
    label: getOptionLabel(option),
  }))

  return (
    <Controller
      name={name}
      control={control}
      rules={{required: required}}
      render={({field: {onChange, value}}) => (
        <div>
          <label htmlFor={name} className='form-label'>
            {label}
          </label>
          <Select
            options={autoCompleteOptions}
            name={name}
            // className={'react-select-styled react-select-solid'}
            // classNamePrefix='react-select'
            className='form-control react-select-styled react-select-transparent'
            classNamePrefix='react-select'
            value={autoCompleteOptions.find((c) => c.value === value)}
            onChange={(val) => onChange(val?.value)}
            isClearable={true}
            backspaceRemovesValue={true}
          />
          {!isEmpty(errors) ? <span className='error text-danger'>{errors}</span> : ''}
        </div>
      )}
    />
  )
}
export default AutoComplete
