import { convertEnumToOptionsWithCleanLabel } from "../utils";

const ArtistSkillLevelEnum = {
  RoyalConservatoryOfMusicQualified: 'RoyalConservatoryOfMusicQualified',
  CollegeGraduate: 'CollegeGraduate',
  DiplomaCertified: 'DiplomaCertified',
  UniversityGraduate: 'UniversityGraduate',
  BachelorsDegree: ' BachleorsDegree',
  MastersDegree: ' MastersDegree',
  PhdOrDoctorateEquivalent: 'PhdOrDoctorateEquivalent',
  AnySkillLevel: 'AnySkillLevel',
  
};

type ArtistSkillLevelEnumType = typeof ArtistSkillLevelEnum[keyof typeof ArtistSkillLevelEnum];

function getArtistSkillLevelEnumOptions() {
  return convertEnumToOptionsWithCleanLabel(ArtistSkillLevelEnum, printArtistSkillLevel)
}

function getArtistSkillLevel(value: ArtistSkillLevelEnumType): ArtistSkillLevelEnumType[]{
  const output = [];
  output.push(ArtistSkillLevelEnum.RoyalConservatoryOfMusicQualified);
  output.push(ArtistSkillLevelEnum.CollegeGraduate);
  output.push(ArtistSkillLevelEnum.DiplomaCertified);
  output.push(ArtistSkillLevelEnum.UniversityGraduate);
  output.push(ArtistSkillLevelEnum.BachelorsDegree);
  output.push(ArtistSkillLevelEnum.MastersDegree);
  output.push(ArtistSkillLevelEnum.PhdOrDoctorateEquivalent);
  output.push(ArtistSkillLevelEnum.AnySkillLevel);
  return output;
}

function printArtistSkillLevel(value: ArtistSkillLevelEnumType): string {
  switch (value) {
    case ArtistSkillLevelEnum.RoyalConservatoryOfMusicQualified:
      return 'Royal Conservatory of Music Qualified';
    case ArtistSkillLevelEnum.CollegeGraduate:
      return 'College Graduate';
    case ArtistSkillLevelEnum.DiplomaCertified:
      return 'Diploma Certified';
    case ArtistSkillLevelEnum.UniversityGraduate:
      return 'University Graduate';
      case ArtistSkillLevelEnum.BachelorsDegree:
      return "Master's Degree";
    case ArtistSkillLevelEnum.MastersDegree:
      return "Bachelor's Degree";
    case ArtistSkillLevelEnum.PhdOrDoctorateEquivalent:
      return 'PhD or Doctorate Equivalent';
      case ArtistSkillLevelEnum.AnySkillLevel:
      return 'Any skill level';
    default:
      return '';
      break;
  }
}

export { getArtistSkillLevel, printArtistSkillLevel, ArtistSkillLevelEnum,getArtistSkillLevelEnumOptions };
