import {ReactNode} from 'react'

interface Props {
  id: string
  children: ReactNode
}

const Accordion = ({id, children}: Props) => {
  return (
    <>
      <div className='accordion' id={id}>
        {children}
      </div>
    </>
  )
}

export default Accordion
