import {ReactNode} from 'react'

interface Props {
  children: ReactNode
}

const H3 = ({children,...props}: Props) => {
  return <h3 {...props}>{children}</h3>
}

export default H3