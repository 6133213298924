import { Auth, User } from "../entities"

export default class UserMapper{
  mapToEntity(data: Partial<User>): User {
    const user: User = {
      id: data.id || 0,
      userName: data.userName || '',
      password: data.password || undefined,
      email: data.email || '',
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      fullName: data.fullName,
      discriminator: data.discriminator,
      phoneNumber: data.phoneNumber,
      roles: data.roles || [],
      token: data.token || ({} as Auth),
      name: data.name || '',
      uuid:  data.uuid || '',
      isOnboarding: data.isOnboarding || false, 
      defaultUuid: data.defaultUuid || undefined, 
      associatedUuids: data.associatedUuids || [], 
    } 
  
    return user
  }
}
