/* eslint-disable jsx-a11y/anchor-is-valid */
import {P, Title} from '../../../components'

export default function Glossary() {
  return (
    <>
      <div className='text-center'>
        <Title type='xxs'>Glossary Page</Title>
      </div>

      <h2 className='mt-10 text-2xl text-center'>Artist</h2>
      <h3 className='mb-4 text-center'></h3>
      <p className='text-center'>
        def.”a creator or developer of an artistic product. They contribute to the physical
        production of music, a record or album, or curriculum. They must physically prove they are a
        creator or some sort.”
      </p>
      <h2 className='mt-10 text-2xl text-center'>Venue</h2>
      <h3 className='mb-4 text-center'></h3>
      <p className='text-center'>
        def.”A location or space designed to host and accommodate artists. They must have a physical
        address.”
      </p>
      <h2 className='mt-10 text-2xl text-center'>Agent</h2>
      <h3 className='mb-4 text-center'></h3>
      <p className='text-center'>
        def.” A person or organization who works to supply content to Artists of Venues, or those
        who represent an Artist, Venue, or Agency.”
      </p>
      <h2 className='mt-10 text-2xl text-center'>PERFORMER</h2>
      <h3 className='mb-4 text-center'>ALSO CALLED: A soloist, duo</h3>
      <p className='text-center'>
        def.“A single permanent musician that plays stage, or for an audience.”
      </p>
      <h2 className='mt-10 text-2xl text-center'>BAND</h2>
      <h3 className='mb-4 text-center'>ALSO CALLED: A music group</h3>
      <p className='text-center'>
        def.”More than one permanent musician or a group of people who plays on stage.”
      </p>
      <h2 className='mt-10 text-2xl text-center'>SESSION ARTIST</h2>
      <h3 className='mb-4 text-center'></h3>
      <p className='text-center'>
        def.”A freelance musician, band, or singer hired for a project on a temporary or short-term
        basis.”
      </p>
      <h2 className='mt-10 text-2xl text-center'>INSTRUMENTALIST</h2>
      <h3 className='mb-4 text-center'></h3>
      <p className='text-center'>
        def."A freelance musician who plays an instrument, hired to perform live in front of an
        audience or in a band.”
      </p>
      <h2 className='mt-10 text-2xl text-center'>RECORD PRODUCER</h2>
      <h3 className='mb-4 text-center'>ALSO CALLED: A music producer</h3>
      <p className='text-center'>
        def.”A person who controls recording sessions, coaching and guiding musicians, organizing
        and scheduling productions budgets and resources, and supervising the recording, mixing, and
        mastering process. (collinsdictionary.com)"
      </p>
      <h2 className='mt-10 text-2xl text-center'>SOUND ENGINEER</h2>
      <h3 className='mb-4 text-center'>
        ALSO CALLED: A recording studio engineer, audio engineer, mixing, mastering
      </h3>
      <p className='text-center'>
        def.”A person whose job is to be responsible for the technical features of the sound for a
        broadcast, performance, or recording. (Cambridge Dictionary)” live & FOH (Front of House)
        ENGINEER: Live sound mix engineers are responsible for controlling the volume, balance, and
        EQ of a live performance from a mixing console.(BERKLEE.EDU) vs MIX ENGINEER: A music
        engineer is a technical specialist who controls the setup and operation of equipment that
        records, edits, mixes and reproduces sound.(INDEED) vs MASTERING ENGINEER: Mastering
        engineers use technical expertise and superb ears to make the final adjustments to a piece
        of recorded music before it's released, heightening its impact and ensuring that it will
        translate well to the variety of playback systems in use today. (BERKLEE.EDU) vs MONITOR
        ENGINEER:A monitor engineer mixes the sound the musicians listen to onstage during a
        performance.(SOUNDGIRLS)
      </p>
      <h2 className='mt-10 text-2xl text-center'>MUSIC TEACHER</h2>
      <h3 className='mb-4 text-center'></h3>
      <p className='text-center'>
        def.”A permanent music teaching position in a school or substitute music instructor that
        teaches a variety of instruments, voice, or subjects such as theory, sight-reading, band,
        etc.”
      </p>
      <h2 className='mt-10 text-2xl text-center'>VOCAL COACH</h2>
      <h3 className='mb-4 text-center'>
        ALSO CALLED: A vocal health specialist, singing teacher, voice instructor
      </h3>
      <p className='text-center'>def.”A one-on-one voice teacher who specializes in singing.”</p>
      <h2 className='mt-10 text-2xl text-center'>CLINICIAN</h2>
      <h3 className='mb-4 text-center'>ALSO CALLED: A guest musician, specialist</h3>
      <p className='text-center'>
        def.”A temporar\y or guest instrumentalist or conductor who comes to a school and instructs
        with a primary teacher.”
      </p>
      <h2 className='mt-10 text-2xl text-center'>MUSIC VENUE</h2>
      <h3 className='mb-4 text-center'>ALSO CALLED: A theatre, auditorium, hall</h3>
      <p className='text-center'>
        def.”a permanent or temporary venue where a multi-stage/performance event takes place. (Law
        Insider)”
      </p>
      <h2 className='mt-10 text-2xl text-center'>FESTIVAL</h2>
      <h3 className='mb-4 text-center'>ALSO CALLED: A fair, jamboree, exhibition</h3>
      <p className='text-center'>
        def.”an organized event, typically lasting several days, featuring performances by various
        musicians, singers, and groups. in a semi-permanent or permanent location.”
      </p>
      <h2 className='mt-10 text-2xl text-center'>POP-UP</h2>
      <h3 className='mb-4 text-center'></h3>
      <p className='text-center'>
        def.”an organized event, typically lasting \a short period of time, featuring performances
        by various musicians, singers, and groups, in a random or non-permanent location.”
      </p>
      <h2 className='mt-10 text-2xl text-center'>RECORDING STUDIO</h2>
      <h3 className='mb-4 text-center'>ALSO CALLED: A live room</h3>
      <p className='text-center'>
        def."a place where, by means of mechanical or electronic devices, voices, music, or other
        sounds are transmitted to tapes, records, or other devices capable of reproducing sound.
        (Law Insider)”
      </p>
      <h2 className='mt-10 text-2xl text-center'>REHEARSAL SPACE</h2>
      <h3 className='mb-4 text-center'>ALSO CALLED: A practice space, a practice room</h3>
      <p className='text-center'>
        def.”A music rehearsal space is a dedicated acoustic environment. This can be{' '}
      </p>
      <p className='text-center'>
        defined by its purpose, layout, furnishings and treatments used to modify the practice rooms
        within a space. (BandSpace.ifo)”
      </p>
      <h2 className='mt-10 text-2xl text-center'>RETAIL</h2>
      <h3 className='mb-4 text-center'>ALSO CALLED: A music store</h3>
      <p className='text-center'>
        def.”a STORE With a permanent location, that sells music products such as music,
        instruments, or accessories associated to sound and music”
      </p>
      <h2 className='mt-10 text-2xl text-center'>RENTALS</h2>
      <h3 className='mb-4 text-center'></h3>
      <p className='text-center'>
        def.”a store with a permanent location, that rents music products such as instruments, sound
        equipment, or accessories associated with music.”
      </p>
      <h2 className='mt-10 text-2xl text-center'>REPAIRS</h2>
      <h3 className='mb-4 text-center'></h3>
      <p className='text-center'>def.”a person or store that fixes music equipment.”</p>
      <h2 className='mt-10 text-2xl text-center'>SCHOOL</h2>
      <h3 className='mb-4 text-center'>ALSO CALLED: A music studio, a lesson centre</h3>
      <p className='text-center'>
        def.”an organization with a fixed address that has multiple music instructors and studios.”
      </p>
      <h2 className='mt-10 text-2xl text-center'>ACADEMY</h2>
      <h3 className='mb-4 text-center'>ALSO CALLED: A conservatory</h3>
      <p className='text-center'>
        def.”an institution that specializes in intermediate or advanced music achievement.”
      </p>
      <h2 className='mt-10 text-2xl text-center'>EVENT MANAGER</h2>
      <h3 className='mb-4 text-center'></h3>
      <h3 className='mb-4 text-center'>
        ALSO CALLED: A production manager, stage manager, venue manager
      </h3>
      <p className='text-center'>
        def."oversee all the activities, employees, technology, and business associated with an
        event.”
      </p>
      <h2 className='mt-10 text-2xl text-center'>EVENT TECHNICIAN</h2>
      <h3 className='mb-4 text-center'>
        ALSO CALLED: Concert technicians, roadie, road crew, instrument tech, backline tech
      </h3>
      <p className='text-center'>
        def.”fabricates and installs props, lighting, and sound systems in a live performance
        venue.”
      </p>
      <h2 className='mt-10 text-2xl text-center'>PRODUCTION COMPANY</h2>
      <h3 className='mb-4 text-center'></h3>
      <p className='text-center'>
        def.”The primary function of a production company is to locate artists and facilitate the
        recording, manufacturing and distribution of musical product.”
      </p>
      <h2 className='mt-10 text-2xl text-center'>BACKLINE COMPANY</h2>
      <h3 className='mb-4 text-center'></h3>
      <p className='text-center'>
        def.”In the simplest terms, Backline refers to everything the musicians need to perform
        except the P.A. system, microphones, and stage monitors. Amps, speakers, keyboards, drums,
        percussion, and even music stands, instrument stands, and risers are all a part of the
        Backline. A backline company usually rents this gear to its clients.”
      </p>
      <h2 className='mt-10 text-2xl text-center'>LABEL</h2>
      <h3 className='mb-4 text-center'>
        ALSO CALLED: Record Labels, Record Companies, Music Publishers
      </h3>
      <p className='text-center'>
        def.”a company that produces and sells records, CDs, and recordings. Record labels are
        companies, large or small, that manufacture, distribute, and promote the recordings of
        affiliated musicians. Essentially, record labels work to sell the brand of the artist and
        the products they create. (The Free Dictionary)” MUSIC PUBLISHER: Music publisher can refer
        both to an organization that secures and manages copyrights for musical compositions and to
        the person who runs it. Their tasks include promoting their catalog's songs to recording
        artists; licensing compositions for use by films, television, advertisements, and other
        media; monitoring song usage; and collecting and distributing royalties to
        clients.(Berklee.edu) vs RECORD LABEL: A record label, or record company, or simply records,
        is a brand or trademark of music recordings and music videos, or the company that owns it.
      </p>
      <h2 className='mt-10 text-2xl text-center'>MANAGEMENT</h2>
      <h3 className='mb-4 text-center'>ALSO CALLED: An agency, management firm</h3>
      <p className='text-center'>
        def.”A person (or group of people) who oversees the business affairs of a musician or band.
        (masterclassName.com)"
      </p>
      <h2 className='mt-10 text-2xl text-center'>BOOKING AGENT</h2>
      <h3 className='mb-4 text-center'>
        ALSO CALLED: Venue booker, travel agent. AFFILIATED WITH AN ARTIST
      </h3>
      <p className='text-center'>
        def.”work with artists to schedule concerts, tours, and in-person appearances, and to
        negotiate fees and contracts for those bookings. (Berklee)”
      </p>
      <h2 className='mt-10 text-2xl text-center'>TALENT BUYER</h2>
      <h3 className='mb-4 text-center'>
        ALSO CALLED: Venue booker, AFFILIATED WITH A VENUE, NOT AN ARTIST.
      </h3>
      <p className='text-center'>
        def.”Identifying artists appropriate for a venue, negotiating with their agents and
        managers, and booking them are all central to the talent buyer's job. Besides having a good
        ear, talent buyers have to understand the many variables that go into a successful show,
        including marketing, setting ticket prices, and managing concession sales. (Berklee.edu)”
      </p>
      <h2 className='mt-10 text-2xl text-center'>EVENT PROMOTER</h2>
      <h3 className='mb-4 text-center'>
        ALSO CALLED: Tour promoter, promoter, concert promoter, NOT AFFILIATED WITH VENUE OR ARTIST
      </h3>
      <p className='text-center'>
        def.”Concert promoters organize and present live music events, taking care of everything
        from booking talent and securing venues to setting ticket prices and marketing the show.
        (Berklee.edu)”
      </p>
    </>
  )
}
