import { convertEnumToOptionsWithCleanLabel } from "../utils/CommonUtil"

enum VenueCapacityEnum {
  lessThanFifty = '<50',
  hundred = '50100',
  hundredPlus = '100200',
  twoHundredPlus = '200300',
  fourHundredPlus = '400',
  thousandPlus = '1000',
}

type VenueCapacityType = typeof VenueCapacityEnum[keyof typeof VenueCapacityEnum]

function getVenueCapacityTypes(): VenueCapacityType[] {
  return Object.values(VenueCapacityEnum)
}


function printVenueCapacity(value: VenueCapacityEnum): string | undefined {
  switch (value) {
    case VenueCapacityEnum.lessThanFifty:
      return '50'
    case VenueCapacityEnum.hundred:
      return '50-100'
    case VenueCapacityEnum.hundredPlus:
      return '100-200'
    case VenueCapacityEnum.twoHundredPlus:
      return '200-300'
    case VenueCapacityEnum.fourHundredPlus:
      return '400+'
    case VenueCapacityEnum.thousandPlus:
      return '1000+'
    default:
      return ''
  }
}

function getVenueCaptionOptions(){
  return convertEnumToOptionsWithCleanLabel(
       VenueCapacityEnum,
       printVenueCapacity
     )
}

export {getVenueCapacityTypes, printVenueCapacity, VenueCapacityEnum, getVenueCaptionOptions}
