
enum VenueFoodOptionsEnum {
WeServeFood = 'WeServeFood',
WeServeAlcohol = 'WeServeAlcohol',
WeAllowOutsideCatering = 'WeAllowOutsideCatering',
WeAllowMinors = 'WeAllowMinors',
}

type VenueFoodOptionsType = typeof VenueFoodOptionsEnum[keyof typeof VenueFoodOptionsEnum]

function getVenueFoodOptionsTypes(): VenueFoodOptionsType[] {
        return Object.values(VenueFoodOptionsEnum);
      } 



function printVenueFoodOptions(value: VenueFoodOptionsEnum): string | undefined { 
switch (value) { 
       case VenueFoodOptionsEnum.WeServeFood:
        return 'We serve food.';
       case VenueFoodOptionsEnum.WeServeAlcohol:
        return 'We serve alcohol.';
       case VenueFoodOptionsEnum.WeAllowOutsideCatering:
        return 'We allow outside catering.';
       case VenueFoodOptionsEnum.WeAllowMinors:
        return 'We allow minors.';
      default:
    return "";
        }
     }


export { getVenueFoodOptionsTypes, printVenueFoodOptions, VenueFoodOptionsEnum }; 
