import {Option} from '../../ui/interfaces/Option'
import { convertEnumToOptionsWithCleanLabel } from '../utils'

const SessionArtistInstrumentalistEnum = {
  Vocalist: 'Vocalist',
  Instrumentalist: 'Instrumentalist',
  ComposerArranger: 'ComposerArranger',
}

type SessionArtistInstrumentalistEnumType =
  typeof SessionArtistInstrumentalistEnum[keyof typeof SessionArtistInstrumentalistEnum]

function getAllSessionArtistInstrumentalists(
  value: SessionArtistInstrumentalistEnumType
): SessionArtistInstrumentalistEnumType[] {
  const instrumentalists = []
  instrumentalists.push(SessionArtistInstrumentalistEnum.Vocalist)
  instrumentalists.push(SessionArtistInstrumentalistEnum.Instrumentalist)
  instrumentalists.push(SessionArtistInstrumentalistEnum.ComposerArranger)
  return instrumentalists
}
function getSessionInstrumentalistEnumOptions() {
  return convertEnumToOptionsWithCleanLabel(SessionArtistInstrumentalistEnum, printInstrumentalist)
}

function getSessionArtistInstrumentalistOptions(
  value: SessionArtistInstrumentalistEnumType
): Option[] {
  const instrumentalistOptions: Option[] = []
  instrumentalistOptions.push({
    value: SessionArtistInstrumentalistEnum.Vocalist,
    label: `${printInstrumentalist(SessionArtistInstrumentalistEnum.Vocalist)}`,
  })
  instrumentalistOptions.push({
    value: SessionArtistInstrumentalistEnum.Instrumentalist,
    label: `${printInstrumentalist(SessionArtistInstrumentalistEnum.Instrumentalist)}`,
  })
  instrumentalistOptions.push({
    value: SessionArtistInstrumentalistEnum.ComposerArranger,
    label: `${printInstrumentalist(SessionArtistInstrumentalistEnum.ComposerArranger)}`,
  })
  return instrumentalistOptions
}

function printInstrumentalist(value: SessionArtistInstrumentalistEnumType): string {
  switch (value) {
    case SessionArtistInstrumentalistEnum.Vocalist:
      return 'Vocalist'
    case SessionArtistInstrumentalistEnum.Instrumentalist:
      return 'Instrumentalist'
    case SessionArtistInstrumentalistEnum.ComposerArranger:
      return 'Composer / Arranger'
    default:
      return ''
  }
}

const sessionArtistInstrumentalistTypes = Object.keys(SessionArtistInstrumentalistEnum)

export {
  getAllSessionArtistInstrumentalists,
  getSessionArtistInstrumentalistOptions,
  printInstrumentalist,
  sessionArtistInstrumentalistTypes,
  SessionArtistInstrumentalistEnum,
  getSessionInstrumentalistEnumOptions
}
