import {ArtistEnum} from '../../domain/enums/ArtistEnum'
import {PerformerBand} from '../../domain/entities/artist/PerformerBand'
import {OnboardingRequest} from '../../domain/models/requests/common/OnboardingRequest'
import {
  isEmpty,
  mapEnumsToString,
  mapOptionsToCommaString,
  safeItemSelectionInCollection,
  safeLocationTextId,
  safeNullableString,
  safeValueFloat,
  safeYesNoBoolean,
} from '../../domain/utils'
import {RecordProducerStudioEngineer} from '../../domain/entities/artist/RecordProducerStudioEngineer'
import {SessionArtistInstrumentals} from '../../domain/entities/artist/SessionArtistInstrumentals'
import {TeacherClinician} from '../../domain/entities/artist/TeacherClinician'
import {EngineerTypeEnum} from '../../domain/enums/EngineerTypeEnum'
import {Artist} from '../../domain/entities/Artist'
import {SessionInstrumentalistServiceEnum} from '../../domain/enums/SessionInstrumentalistServiceEnum'

import {ArtistSkillLevelEnum} from '../../domain/enums/ArtistSkillLevelEnum'
import {ArtistOnboardingRequest} from '../../domain/models/requests/common/ArtistOnboardingRequest'
import {TeacherClinicianTypeEnum} from '../../domain/enums/TeacherClinicianTypeEnum'
import {TeacherClinicianFieldTypeEnum} from '../../domain/enums/TeacherClinicianFieldTypeEnum'
import {AgeGroupEnum} from '../../domain/enums/AgeGroupEnum'
import {ArtistMusicLiteracyEnum} from '../../domain/enums/ArtistMusicLiteracyEnum'
import {VenueOnboardingRequest} from '../../domain/models/requests/common/VenueOnboardingRequest'
import {AgentOnboardingRequest} from '../../domain/models/requests/common/AgentOnboardingRequest'
import {Venue} from '../../domain/entities/Venue'
import {AgentTypeEnum} from '../../domain/enums/AgentTypeEnum'
import {Agent} from '../../domain/entities/Agent'
import {VenueEnum} from '../../domain/enums/VenueEnum'
import {MusicVenue} from '../../domain/entities/venue/MusicVenue'
import {SchoolAcademyLessons} from '../../domain/entities/venue/SchoolAcademyLessons'
import {RecordingStudioRehearsalSpace} from '../../domain/entities/venue/RecordingStudioRehearsalSpace'
import {RetailRentalRepairShop} from '../../domain/entities/venue/RetailRentalRepairShop'
import { BookingAgentTalentBuyerContractor } from '../../domain/entities/agent/BookingAgentTalentBuyerContractor'
import { EventManagerEventTechnician } from '../../domain/entities/agent/EventManagerEventTechnician'
import { PromoterManagementAgent } from '../../domain/entities/agent/PromoterManagementAgent'

export function createOnboardingRequest(request: any) {
  if (!isEmpty(request.accountType)) {
    let userOnboarding: OnboardingRequest = {
      type: request.accountType,
      initiated_by: request.initiatedBy,
      uuid: request.uuid,
    }
    if (request.accountType === 'Artist') {
      let artistOnboarding: ArtistOnboardingRequest = {
        uuid: request.uuid,
      }
      if (!isEmpty(request.artistType)) {
        const provinceIds = request.prefArtistProvinceId
        const cityIds =  request.prefArtistCityId
        const artist: Artist = {
          type: request.artistType, //Get Artist Type
          name: request.name,
          genres: mapOptionsToCommaString(request.genres),

     
          location_id: safeLocationTextId(1, provinceIds, cityIds),
          website: `https://${request.website}`,
          contact: request.emailAddress,
          phone: request.phone,
          phone_main: request.phone,
          phone_other: '',
          other_name: '', // ADD THIS TO Artist UI
          youtube: request.youtubeUrl,
          wikipedia: request.wikipedia,
          spotify: request.spotify, // ADD THIS TO SOCIAL UI
          apple_music: request.appleMusic,
          google_play: request.googlePlay,
          itunes: '', // ADD THIS TO SOCIAL UI
          founded: '',
          indigenous: undefined, // ADD THIS TO Artist UI
          black: undefined, // ADD THIS TO Artist UI
          hispanic_latino: undefined, // ADD THIS TO Artist UI
          lgbtq: undefined, // ADD THIS TO Artist UI
          size: request.artistBandSize,
          has_label: safeYesNoBoolean(request.artistHasLabel),
          label_name: request.artistLabelName,
          minimum_price: safeValueFloat(request.minimumPrice),
          has_hourly_rate: safeYesNoBoolean(request.artistPriceType),
          hourly_rate_price: safeValueFloat(request.artistHourlyRate),
          contact_firstname: request.firstNmae,
          contact_lastname: request.lastName,

          affiliations: request.professionalAffiliations,
          performance_preference: mapOptionsToCommaString(request.performancePreference),
          ethnic_identity: mapEnumsToString(request.ethnicIdentity),
          repesenting_artist: request.representingArtist,
          representing_label_agency: request.representingLabelOrAgency,
          facebook: request.facebookUrl,
          instagram: request.instagramUrl,
          tiktok: request.tiktokUrl,
          stage_plot: request.stagePlot,

          //TODO:: add charity column to artist table
          contact_name: request.name,
        }
        switch (artist.type) {
          case ArtistEnum.PerformerBand:
            const performerBand: PerformerBand = {
              has_explicit_content: safeYesNoBoolean(request.explicitContent),
              has_a_sound_tech: safeYesNoBoolean(request.artistHasSoundTech), // ADD THIS TO Performer Boolean
              has_a_lighting_tech: safeYesNoBoolean(request.artistHasALightingTech),
              name: request.name,
              plays_originals_covers_both: request.artistOriginalCovers,
              subtype: mapEnumsToString(request.artistPerformerBandType),
              //TODO:: add to database
              years_of_experience: request.yearsOfExperienceAsPerformerBand,
            }
            artistOnboarding = Object.assign(artistOnboarding, {
              performerBand: performerBand,
              artist: artist,
            })
            userOnboarding = Object.assign(userOnboarding, {
              artist: artistOnboarding,
            })
            return userOnboarding;  
          case ArtistEnum.RecordProducerStudioEngineer:
            const recordProducerStudioEngineer: RecordProducerStudioEngineer = {
              location_id: request.artistCityId,
              live_and_foh: safeItemSelectionInCollection(
                EngineerTypeEnum.LiveFOHEngineer,
                request.recordProducerEngineerTypes
              ),
              mix_and_production: safeItemSelectionInCollection(
                EngineerTypeEnum.MixProductionEngineer,
                request.recordProducerEngineerTypes
              ),
              mastering: safeItemSelectionInCollection(
                EngineerTypeEnum.MasteringEngineer,
                request.recordProducerEngineerTypes
              ),
              recording: safeItemSelectionInCollection(
                EngineerTypeEnum.RecordingEngineer,
                request.recordProducerEngineerTypes
              ),
              monitor: safeItemSelectionInCollection(
                EngineerTypeEnum.MonitorEngineer,
                request.recordProducerEngineerTypes
              ),
            }

            artistOnboarding = Object.assign(artistOnboarding, {
              recordProducerStudioEngineer: recordProducerStudioEngineer,
            })
            userOnboarding = Object.assign(userOnboarding, {
              artist: artistOnboarding,
            })

            break

          case ArtistEnum.SessionArtistInstrumentals:
            const sessionArtistInstrumentals: SessionArtistInstrumentals = {
              location_id: request.cityId,

              is_online: safeYesNoBoolean(request.artistOnlineServices),
              offers_rehearsals: safeItemSelectionInCollection(
                SessionInstrumentalistServiceEnum.Rehearsals,
                request.artistSessionServiceTypes
              ),
              offers_performances: safeItemSelectionInCollection(
                SessionInstrumentalistServiceEnum.Performances,
                request.artistSessionServiceTypes
              ),
              offers_recordings: safeItemSelectionInCollection(
                SessionInstrumentalistServiceEnum.Recordings,
                request.artistSessionServiceTypes
              ),
              literacy_charts: safeItemSelectionInCollection(
                ArtistMusicLiteracyEnum.Charts,
                request.sessionInstrumentalistMusicLiteracy
              ),
              literacy_tabs: safeItemSelectionInCollection(
                ArtistMusicLiteracyEnum.Tabs,
                request.sessionInstrumentalistMusicLiteracy
              ),
              literacy_music_notation: safeItemSelectionInCollection(
                ArtistMusicLiteracyEnum.MusicNotation,
                request.sessionInstrumentalistMusicLiteracy
              ),

              skill_royal_conservatory_of_music_qualified: safeItemSelectionInCollection(
                ArtistSkillLevelEnum.RoyalConservatoryOfMusicQualified,
                request.artistSessionSkills
              ),
              skill_university_graduate: safeItemSelectionInCollection(
                ArtistSkillLevelEnum.UniversityGraduate,
                request.artistSessionSkills
              ),
              skill_masters_degree: safeItemSelectionInCollection(
                ArtistSkillLevelEnum.MastersDegree,
                request.artistSessionSkills
              ),
              skill_phd_or_doctorate_equivalent: safeItemSelectionInCollection(
                ArtistSkillLevelEnum.PhdOrDoctorateEquivalent,
                request.artistSessionSkills
              ),
              skill_college_graduate: safeItemSelectionInCollection(
                ArtistSkillLevelEnum.CollegeGraduate,
                request.artistSessionSkills
              ),

              //TODO:: Add Columns to DB
              //type: request.sessionInstrumentalistTypes
              //instruments: request.sessionInstrumentalistInstruments
              // literacy_i_cant_read_music: safeItemSelectionInCollection(
              //   ArtistMusicLiteracyEnum.ICantRead,
              //   request.sessionInstrumentalistMusicLiteracy
              // ),
              // years_of_experience_as_session_instrumentalist: request.yearsOfExperienceAsSessionInstrumentalist,
            }
            artistOnboarding = Object.assign(artistOnboarding, {
              sessionArtistInstrumentals: sessionArtistInstrumentals,
            })
            userOnboarding = Object.assign(userOnboarding, {
              artist: artistOnboarding,
            })

            break
          case ArtistEnum.TeacherClinician:
            const teacherClinician: TeacherClinician = {
              recent_criminal_record_check: safeYesNoBoolean(
                request.artistTeacherRecentCriminalRecordCheck
              ),
              type_full_time_instructor: safeItemSelectionInCollection(
                TeacherClinicianTypeEnum.FullTimeInstructor,
                request.artistTeacherClinicianType
              ),
              type_substitute_teacher: safeItemSelectionInCollection(
                TeacherClinicianTypeEnum.SubstituteTeacher,
                request.artistTeacherClinicianType
              ),
              type_clinician_master_class: safeItemSelectionInCollection(
                TeacherClinicianTypeEnum.ClinicianOrMasterClass,
                request.artistTeacherClinicianType
              ),
              type_mobile: safeItemSelectionInCollection(
                TeacherClinicianTypeEnum.Mobile,
                request.artistTeacherClinicianType
              ),
              type_online: safeItemSelectionInCollection(
                TeacherClinicianTypeEnum.Online,
                request.artistTeacherClinicianType
              ),
              skill_royal_conservatory_of_music_qualified: safeItemSelectionInCollection(
                ArtistSkillLevelEnum.RoyalConservatoryOfMusicQualified,
                request.artistTeacherSkills
              ),
              skill_university_graduate: safeItemSelectionInCollection(
                ArtistSkillLevelEnum.UniversityGraduate,
                request.artistTeacherSkills
              ),
              skill_masters_degree: safeItemSelectionInCollection(
                ArtistSkillLevelEnum.MastersDegree,
                request.artistTeacherSkills
              ),
              skill_phd_or_doctorate_equivalent: safeItemSelectionInCollection(
                ArtistSkillLevelEnum.PhdOrDoctorateEquivalent,
                request.artistTeacherSkills
              ),
              skill_college_graduate: safeItemSelectionInCollection(
                ArtistSkillLevelEnum.CollegeGraduate,
                request.artistTeacherSkills
              ),
              improvisation: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.Improvisation,
                request.artistTeacherFields
              ),
              instrument_studies: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.InstrumentStudies,
                request.artistTeacherFields
              ),
              vocal_studies: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.VocalStudies,
                request.artistTeacherFields
              ),
              orchestral_instrument_studies: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.OrchestralInstrumentStudies,
                request.artistTeacherFields
              ),
              wind_instrument_studies: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.WindInstrumentStudies,
                request.artistTeacherFields
              ),
              intermediate_theory: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.IntermediateTheory,
                request.artistTeacherFields
              ),
              intermediate_keyboard_skills: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.IntermediateKeyboardSkills,
                request.artistTeacherFields
              ),
              basic_keyboard_skills: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.BasicKeyboardSkills,
                request.artistTeacherFields
              ),
              advanced_theory: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.AdvancedTheory,
                request.artistTeacherFields
              ),
              advanced_keyboard_skills: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.AdvancedKeyboardSkills,
                request.artistTeacherFields
              ),
              classical_music_history: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.ClassicalMusicHistory,
                request.artistTeacherFields
              ),
              popular_music_history: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.PopularMusicHistory,
                request.artistSessionSkills
              ),
              intermediate_aural_skills: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.IntermediateAuralSkills,
                request.artistTeacherFields
              ),
              harmony_counterpoint: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.HarmonyCounterpoint,
                request.artistTeacherFields
              ),
              songwriting: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.Songwriting,
                request.artistTeacherFields
              ),
              advanced_aural_skills: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.AdvancedAuralSkills,
                request.artistTeacherFields
              ),
              basic_aural_skills: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.BasicAuralSkills,
                request.artistTeacherFields
              ),
              composition: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.Composition,
                request.artistTeacherFields
              ),
              arranging: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.Arranging,
                request.artistTeacherFields
              ),
              jazz_studies: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.JazzStudies,
                request.artistTeacherFields
              ),
              sound_production_engineering: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.SoundProductionEngineering,
                request.artistTeacherFields
              ),
              video_game_scoring_production: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.VideoGameScoringProduction,
                request.artistTeacherFields
              ),
              film_scoring: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.FilmScoring,
                request.artistTeacherFields
              ),
              performance: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.Performance,
                request.artistTeacherFields
              ),
              music_technology: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.MusicTechnology,
                request.artistTeacherFields
              ),
              stage_management_tech: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.StageManagementTech,
                request.artistTeacherFields
              ),
              marketing_promotion: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.MarketingPromotion,
                request.artistTeacherFields
              ),
              tour_management_music_business: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.TourManagementMusicBusiness,
                request.artistTeacherFields
              ),
              music_education: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.MusicEducation,
                request.artistTeacherFields
              ),
              artist_management_business: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.ArtistManagementBusiness,
                request.artistTeacherFields
              ),
              copyright_law: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.CopyrightLaw,
                request.artistTeacherFields
              ),
              fun_games_activities: safeItemSelectionInCollection(
                TeacherClinicianFieldTypeEnum.FunGamesActivities,
                request.artistTeacherFields
              ),
              pre_school: safeItemSelectionInCollection(
                AgeGroupEnum.PreSchoolKindergarten,
                request.artistTeacherAgeGroups
              ),
              elementary: safeItemSelectionInCollection(
                AgeGroupEnum.Elementary,
                request.artistTeacherAgeGroups
              ),
              junior_high: safeItemSelectionInCollection(
                AgeGroupEnum.JuniorHigh,
                request.artistTeacherAgeGroups
              ),
              senior_high: safeItemSelectionInCollection(
                AgeGroupEnum.SeniorHigh,
                request.artistTeacherAgeGroups
              ),
              college_prep: safeItemSelectionInCollection(
                AgeGroupEnum.PreCollege,
                request.artistTeacherAgeGroups
              ),
              college: safeItemSelectionInCollection(
                AgeGroupEnum.College,
                request.artistTeacherAgeGroups
              ),
              university: safeItemSelectionInCollection(
                AgeGroupEnum.University,
                request.artistTeacherAgeGroups
              ),
              senior: safeItemSelectionInCollection(
                AgeGroupEnum.Senior,
                request.artistTeacherAgeGroups
              ),
              is_online: safeYesNoBoolean(request.artistOnlineServices),

              ////TODO:: add to database //UNcomment this
              // subtype: request.teacherClinicianType,
              // field_of_study: request.teacherClinicianType,
              // instruments: request.teacherClinicianInstruments,
              // student_types: mapOptionsToCommaString(request.teacherClinicianStudents),
              // skill_level: mapOptionsToCommaString(request.teacherClinicianSkillLevelOptions),
              // years_of_experience: request.teacherClinicianYearsOfExperience,
              // group_size: mapOptionsToCommaString(request.teacherClinicianGroupSize),
              // available_time: request.teacherClinicianGroupSize,
            }

            artistOnboarding = Object.assign(artistOnboarding, {
              teacherClinician: teacherClinician,
            })
            userOnboarding = Object.assign(userOnboarding, {
              artist: artistOnboarding,
            })

            break
          default:
            break
        }
      }
    } else if (request.accountType === 'Venue') {
      let venueOboarding: VenueOnboardingRequest = {
        uuid: request.uuid,
      }
      if (!isEmpty(request.artistType)) {
        const venue: Venue = {
          type: request.venueType, //Get Venue Type
          name: request.venueName,
          // location_id: safeLocationId(countryId, provinceId, cityId),
          address: safeNullableString(request.artistWebsite),
          website: request.artistWebsite,
          // contact: artistEmailAddress,
          email_address: request.artistEmailAddress,
          phone: safeNullableString(request.artistPhone),

          youtube: safeNullableString(request.artistYoutube),
          wikipedia: safeNullableString(request.artistWikipedia),
          spotify: safeNullableString(request.artistSpotify),
          apple_music: safeNullableString(request.artistAppleMusic),
          google_play: safeNullableString(request.artistGooglePlay),
          itunes: safeNullableString(''),
          // founded: '',
          //TODO:: add to database
          minimum_price: safeValueFloat(request.minimumPrice),
          capacity: safeValueFloat(request.venueCapacity),
          //TODO:: add to database
          sub_type: safeNullableString(request.subType),
          supplied_equipment: safeNullableString(request.vuppliedEquipment),
          backline_gear: safeNullableString(request.backlineGear),
          food_supply_option: safeNullableString(request.foodOptions),
          extras: safeNullableString(request.extras),
          ethnic_and_identity_info:safeNullableString(request.ethnicVenueIdentity)


        }
        switch (venue.type) {
          case VenueEnum.Music:
            const venueMusic: MusicVenue = {
              // No unique values, all values it has are shared values
            }
            break
          // case VenueEnum.Festivals:
          //   const festivalPopUp: FestivalPopUp = {
          //     genres: mapOptionsToCommaString(request.genres) 
          //   }
          //   break
          case VenueEnum.SchoolAcademyLesson:
            const schoolAcademyLessons: SchoolAcademyLessons = {
              //TODO:: add to database
              administration_times: safeNullableString(request.recordingStudioRentalTimes) 
            }
            break
          case VenueEnum.RecordingStudioRehearsal:
            const recordingStudioRehearsalSpace: RecordingStudioRehearsalSpace = {
              //TODO:: add to database
              rental_times: safeNullableString(request.recordingStudioRentalTimes) 
            }
            break
          case VenueEnum.RetailRentalRepairShop:
            const retailRentalRepairShop: RetailRentalRepairShop = {
              // No unique values, all values it has are shared values

            }
            break
        }
      } else if (request.accountType === 'Agent') {
        let agentOnboardingRequest: AgentOnboardingRequest = {
          uuid: request.uuid,
        }

        const agent: Agent = {
          type: request.agentType, //Get Agent Type
          minimum_price: safeValueFloat(request.minimumPrice),

          // TODO:: Add to database
          sub_type: request.subType,
          years_of_experience: request.yearsOfExperience,
        }
        switch (agent.type) {
          case AgentTypeEnum.BookingAgentTalentBuyerContractor:
            const bookingAgentTalentBuyerContractor: BookingAgentTalentBuyerContractor = {
              // No unique fields
            }
            break
          case AgentTypeEnum.EventManagerEventTechnician:
            const eventManagerEventTechnician: EventManagerEventTechnician = {
              // No unique fields
            }
            break
          case AgentTypeEnum.PromotorManagamentAgent:
            const promoterManagementAgent: PromoterManagementAgent = {
              // No unique fields
            }
            break
        }
      }
    }
  }
}
