import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import {
  I18nProvider,
  LayoutProvider,
  LayoutSplashScreen,
} from '../app/infrastructure/deps/metronic/v2'
import { AuthInit } from './infrastructure/local/auth'
import { MasterInit } from './ui/components/MasterLayout/MasterInit'
import { loadStripe } from '@stripe/stripe-js'
import { PublishableKey } from './infrastructure/constants/stripe'

const stripePromise = loadStripe(PublishableKey);

const App = () => {
  const stripeOptions = {
    // passing the client secret obtained from the server
    clientSecret: '{{CLIENT_SECRET}}',
  };
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export { App }
