import {FC} from 'react'
import {EventManagerEventTechnicianFormProps} from '../../../../management/agent/props'
import {
  convertEnumToOptions,
  convertEnumToOptionsWithCleanLabel,
} from '../../../../../../domain/utils'
import {YesNoEnum, YesNoOnlyEnum} from '../../../../../../domain/enums/YesNoEnum'
import {BandSizeEnum, printBandSize} from '../../../../../../domain/enums/BandSizeEnum'
import {YesNoBothEnum} from '../../../../../../domain/enums/YesNoBothEnum'
import {InputText, Title} from '../../../../../components'
import CheckBoxGroup from '../../../../../components/CheckBoxGroup/CheckBoxGroup'
import {
  EventManagerTechnicianEnum,
  printEventManagerTechnicianType,
} from '../../../../../../domain/enums/EventManagerTechnicianTypeEnum'
import CommunityAdditionalInfoCard from '../../../partials/CommunityAdditionalInfoCard'
import PayRateInfoCard from '../../../partials/PayRateInfoCard'
import LocationMultiSelectCard from '../../../../../components/LocationMultiSelectCard/LocationMultiSelectCard'

const EventManagerEventTechnicianForm: FC<EventManagerEventTechnicianFormProps> = ({
  control,
  errors,
  watch,
  provinces,
}) => {
  const yesNoOptions = convertEnumToOptions(YesNoEnum)
  const yesNoOnlyOptions = convertEnumToOptions(YesNoOnlyEnum)
  const yesNoBothOptions = convertEnumToOptions(YesNoBothEnum)
  const artistBandSizeOptions = convertEnumToOptionsWithCleanLabel(BandSizeEnum, printBandSize)

  const eventManagerTechnicianTypeOptions = convertEnumToOptionsWithCleanLabel(
    EventManagerTechnicianEnum,
    printEventManagerTechnicianType
  )
  return (
    <>

      <div className='col-md-12 mt-5'>
        <LocationMultiSelectCard
          provinceLabel='Where are you wanting to work? Select all that apply'
          cityLabel='Specific City'
          provinceId={'agentProvinceId'}
          cityId={'agentCityId'}
          control={control}
          required={true}
          errors={errors}
          label={'Location'}
          name={'loc-card'}
          provinces={provinces}
          className={''}
          watch={watch}
        />
      </div>

      <div className='col-md-12'>
        <CheckBoxGroup
          name={'subType'}
          label={'You are a...'}
          // errors={errors.musicVenueType?.message}
          options={eventManagerTechnicianTypeOptions}
          // className={"'"}
          control={control}
          className={'mb-5'}
        />
      </div>

      <div className='col-md-6 mt-5'>
        <InputText
          control={control}
          errors={errors.eventManagerTechnicianYearsOfExperience?.message}
          required={false}
          disabled={false}
          name={'yearsOfExperience'}
          label={'Years of Experience in this role.'}
          className='form-control'
          type={'number'}
        />
      </div>

      <CommunityAdditionalInfoCard control={control} errors={errors} />
      <div className='col-md-12 mt-5'>
        <Title type={'xxs'}>Offer Information</Title>
      </div>

      <PayRateInfoCard
        control={control}
        errors={errors}
        rateQuestion='Minimum rate per SET or SERVICE. (A set / service is defined as 3 hours or 180 minutes..)'
      />
    </>
  )
}

export default EventManagerEventTechnicianForm
