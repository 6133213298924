import {convertEnumToOptionsWithCleanLabel} from '../utils'

const YesNoEnum = {
  Yes: 'Yes',
  No: 'No',
  Unknown: 'Unknown',
}

const YesNoHybridEnum = {
  Yes: 'Yes',
  No: 'No',
  Hybrid: 'Hybrid',
}

const YesNoOnlyEnum = {
  Yes: 'Yes',
  No: 'No',
}

function getYesNoOnlyEnumOptions() {
  return convertEnumToOptionsWithCleanLabel(YesNoOnlyEnum, printYesNo)
}
function getYesNoEnumOptions() {
  return convertEnumToOptionsWithCleanLabel(YesNoEnum, printYesNoOnly)
}
function getYesNoHybridEnumOptions() {
  return convertEnumToOptionsWithCleanLabel(YesNoHybridEnum, printYesNoHybrid)
}

type YesNoType = typeof YesNoEnum[keyof typeof YesNoEnum]

function getYesNo(): YesNoType[] {
  const output = []
  output.push(YesNoEnum.Yes)
  output.push(YesNoEnum.No)
  output.push(YesNoEnum.Unknown)
  return output
}

function getYesNoMandatory(): YesNoType[] {
  const output = []
  output.push(YesNoEnum.Yes)
  output.push(YesNoEnum.No)
  return output
}

function getYesNoHybrid(): YesNoType[] {
  const output = []
  output.push(YesNoEnum.Yes)
  output.push(YesNoEnum.No)
  return output
}

function printYesNo(value: YesNoType): string {
  switch (value) {
    case YesNoEnum.Yes:
      return 'Yes'
    case YesNoEnum.No:
      return 'No'
    case YesNoEnum.Unknown:
      return 'N/A'
    default:
      break
  }
  return 'N/A'
}
function printYesNoOnly(value: YesNoType): string {
  switch (value) {
    case YesNoEnum.Yes:
      return 'Yes'
    case YesNoEnum.No:
      return 'No'
    default:
      break
  }
  return 'N/A'
}
function printYesNoHybrid(value: YesNoType): string {
  switch (value) {
    case YesNoEnum.Yes:
      return 'Yes'
    case YesNoEnum.No:
      return 'No'
    case YesNoHybridEnum.Hybrid:
      return 'Hybrid'
    default:
      break
  }
  return 'N/A'
}

export {
  getYesNo,
  printYesNo,
  getYesNoMandatory,
  getYesNoHybrid,
  YesNoEnum,
  YesNoOnlyEnum,
  YesNoHybridEnum,
  getYesNoOnlyEnumOptions,
  getYesNoEnumOptions,
  getYesNoHybridEnumOptions,
}
