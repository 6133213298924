import {Option} from '../../ui/interfaces/Option'

const PaymentRateEnum = {
  Fixed: 'Fixed',
  Hourly: 'Hourly',
}

type PaymentRateEnumType = typeof PaymentRateEnum[keyof typeof PaymentRateEnum]

function getAllPaymentRateOptions(value: PaymentRateEnumType): PaymentRateEnumType[] {
  const items = Object.values(PaymentRateEnum)
  return items
}

function getPaymentRateOptions(): Option[] {
  const items: Option[] = []
  Object.entries(PaymentRateEnum).forEach(([key, value]) => {
    items.push({value: value, label: `${printPaymentRateType(value)}`})
  })
  return items
}

function printPaymentRateType(value: string): string {
  switch (value) {
    case PaymentRateEnum.Fixed:
      return 'Fixed'
    case PaymentRateEnum.Hourly:
      return 'Hourly'
    default:
      return ''
  }
}

const paymentRateObject = Object.keys(PaymentRateEnum)

export {
  PaymentRateEnum,
  printPaymentRateType,
  getAllPaymentRateOptions,
  getPaymentRateOptions,
  paymentRateObject
}
